<template>
    <form class="centered-form"  v-on:submit="this.register()" >
    <Modal
        title="NFT Wargames - Register"
        :center-button="{'text': 'Register' }"
        close-button="true"
        v-on:closed="this.$router.go(-1)"
    >
        <div v-if="is_logging_in === 0">
            <div class="modal-body">
                <div v-if="flash_message !== null" class="alert alert-danger alert-dismissible">
                    <i class="bi me-2 bi-exclamation-triangle-fill" width="16" height="16"></i>{{ flash_message }}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <div class="form-floating mb-3">
                    <input type="text" required v-model="username" class="form-control" id="floatingInput" placeholder="Username">
                    <label for="floatingInput">Username</label>
                </div>
                <div class="form-floating">
                    <input type="password" required v-model="password" class="form-control" id="floatingPassword" placeholder="Password">
                    <label for="floatingPassword">Password</label>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="modal-body">
                <div class="d-flex justify-content-center m-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
    </form>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import Modal from './widgets/Modal'
export default {
    data() {
        return {
            username: '',
            password: ''
        }
    },
    computed: {
        ...mapFields([
            'jwt_token',
            'flash_message',
            'is_logging_in'
        ])
    },
    components:{
        Modal
    },
    mounted() {
        this.username = ""
        this.password = ""
        this.flash_message = null
    },
    beforeRouteUpdate() {
        this.username = ""
        this.password = ""
        this.flash_message = null
    },
    methods:{
        async register(){
            await this.$store.dispatch('register', { username: this.username, password: this.password})
            this.password = ""
            if(this.app_auth.is_authenticated === true){
                this.$router.push({path: 'myProfile'})
            }
        }
    }
}
</script>

