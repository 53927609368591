<template>
    <div id="heroes-container">
        <div class="container">
            <div class="row text-light mt-3" style="row-gap: 30px">
                <div class="col-md-4"  v-for="hero in this.heroes"
                     :key="hero">
                    <Modal
                        :title="hero.nickname +  '(' + hero.id + ')'"
                        :center-button="{'text': 'View Details', 'to':{name:'heroDetails', params: {'heroId':hero.id}}}"

                    >
                        <div class="d-flex">
                            <div style="padding:0 10px">
                                <Sprite style="position:relative; margin-top: 0" :type="hero.heroType.name" :direction="1" :action="1" :loop="true"/>
                                <table class="table">
                                    <tr>
                                        <th>Level</th>
                                        <td>{{ hero.level }}</td>
                                    </tr>
                                    <tr>
                                        <th>Exp</th>
                                        <td style="white-space: nowrap">{{ hero.exp }} / {{ hero.requiredExp}}</td>
                                    </tr>
                                    <tr>
                                        <th>Location</th>
                                        <td>{{ hero.zoneCoordinatePosition }}</td>
                                    </tr>
                                </table>
                            </div>
                            <table class="table">
                                <tr>
                                    <th>Type</th>
                                    <td>{{ hero.heroType.name }}</td>
                                </tr>
                                <tr>
                                    <th>HP</th>
                                    <td>{{ hero.hp }}</td>
                                </tr>
                                <tr>
                                    <th>Physical Attack</th>
                                    <td>{{ hero.physicalAttack }}</td>
                                </tr>
                                <tr>
                                    <th>Magical Attack</th>
                                    <td>{{ hero.magicalAttack }}</td>
                                </tr>
                                <tr>
                                    <th>Physical Defense</th>
                                    <td>{{ hero.physicalDefense }}</td>
                                </tr>
                                <tr>
                                    <th>Magical Defense</th>
                                    <td>{{hero.magicalDefense }}</td>
                                </tr>
                                <tr>
                                    <th>Atk Speed</th>
                                    <td>{{ hero.heroType.attackSpeed }}</td>
                                </tr>
                            </table>
                        </div>

                    </Modal>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import Sprite from "./widgets/Sprite"
import Modal from "./widgets/Modal"

export default {
    data() {
        return {
            loading: false,
            heroes: {}
        }
    },
    methods:{

        async loadHeroes(){
            this.loading = true
            let result = await this.heroService.search({'user.username': this.app_auth.username})
            this.heroes = result
            this.loading = false
        }
    },
    activated() {
        this.loadHeroes()
        this.redeemVisible = true
    },
    components:{
        Sprite, Modal
    },
    watch(){

    },
    computed: {

        ...mapFields([
            'app_auth',
            'flash_message',
            'is_loading_heroes',
            'heroService'
        ])
    },
}
</script>

<style scoped>
#heroes-container{
    grid-area: fullcontent;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
