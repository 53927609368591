<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="p-4">
                    <h1 class="">BATTLE</h1>
                    <p>Start collecting them and doing things so you can have big battles</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-wrap p-3 bd-highlight">
                    <div v-for="battle_type in battle_types" :key="battle_type.id"  class="card m-2" style="width: 16rem;">
                        <div class="card-body d-flex flex-column justify-content-between ">
                            <div>
                                <h4 class="card-title">{{ battle_type.name }}</h4>
                                <h6 class="card-subtitle mb-2 text-muted">Heroes: {{ battle_type.maxUnits }} v {{ battle_type.maxUnits }}</h6>
                                <h6 class="card-subtitle mb-2 text-muted">Dimensions: {{ battle_type.width }} x {{ battle_type.height }}</h6>
                            </div>
                            <p class="card-text">{{ battle_type.summary }}</p>
                            <router-link v-bind:to="{name: 'battleFormation', params: {'battleTypeId': battle_type.id}}" class="btn btn-success">Start</router-link>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-md-12">
                <div class="p-4">
                    <h1 class="">BATTLE HISTORY</h1>
                    <p>Start collecting them and doing things so you can have big battles</p>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Time</th>
                        <th>Winner</th>
                        <th>Battle</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="battle in battles" :key="battle" v-on:click="selectedBattle=battle">
                        <td>{{ battle.time }}</td>
                        <td>{{ battle.winner }}</td>
                    </tr>
                    </tbody>
                </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
    data() {
        return {
            selectedBattle: null
        }
    },
    mounted() {
        this.$store.dispatch('loadBattleTypes')
        this.$store.dispatch('loadBattles')
    },
    computed: {
        ...mapFields([
            'battle_types',
            'battles'
        ])
    },
}
</script>

<style>

</style>
