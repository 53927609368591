<template>

    <form  v-on:submit="login()" class="centered-form"  >
        <Modal
            :title="this.modalTitle"
            :center-button="{'text': this.modalTitle }"
            :right-button="{'text': 'Register','to': 'register'}"
        >
            <template v-if="app_auth.is_authenticated === false">
               <div v-if="is_logging_in === 0">
                    <div v-if="flash_message !== null" class="alert alert-danger alert-dismissible">
                        <i class="bi me-2 bi-exclamation-triangle-fill" width="16" height="16"></i>{{ flash_message }}
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" required v-model="username" class="form-control" id="floatingInput" placeholder="Username">
                        <label for="floatingInput">Username</label>
                    </div>
                    <div class="form-floating">
                        <input type="password" required v-model="password" class="form-control" id="floatingPassword" placeholder="Password">
                        <label for="floatingPassword">Password</label>
                    </div>
               </div>
                <div v-else>
                    <div class="d-flex justify-content-center m-5">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </form>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import Modal from "./widgets/Modal"
export default {
    data() {
        return {
            username: '',
            password: ''
        }
    },
    computed: {
        ...mapFields([
            'app_auth',
            'flash_message',
            'is_logging_in'
        ]),
        modalTitle(){
            if(this.app_auth.is_authenticated === true){
                return "Logout"
            }
            return "Login"
        }
    },
    mounted() {
        this.username = ""
        this.password = ""
        this.flash_message = null
    },
    beforeRouteUpdate() {
        this.username = ""
        this.password = ""
        this.flash_message = null
    },
    components:{
        Modal
    },
    methods:{
        handleClick(){
            if(this.app_auth.is_authenticated === true){
                this.logout()
            }
            else{
                this.login()
            }
        },
        async login(){
            await this.$store.dispatch('login', { username: this.username, password: this.password})
            this.password = ""
            if(this.app_auth.is_authenticated === true){
                this.$router.push({path: 'myProfile'})
            }
        },
        logout(){
            this.$store.dispatch('logout')
            this.username = ""
            this.password = ""
        }
    }
}
</script>


