<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="p-4" v-if="'battleFormations' in this.app_auth.user">
                    <h1 class="" >Your Formation</h1>
                    <h2>{{ this.app_auth.username }}</h2>
                    <hr/>
                    <div v-for="bf in app_auth.user.battleFormations"
                         :key="bf"
                         v-on:click="this.attackingFormation = bf"
                    >
                        {{ bf.name }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="p-4" v-if="'battleFormations' in this.enemy">
                    <h1 class="">Enemy Formation</h1>
                    <h2>{{ this.enemy.username }}</h2>
                    <hr/>
                    <div v-if="this.attackingFormation.name !== ''">
                        <div v-for="bf in this.filteredBattleFormation(this.enemy.battleFormations, this.attackingFormation.battleType.id)"
                             :key="bf"
                             v-on:click="this.defendingFormation = bf"
                        >
                            {{ bf.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div v-if="this.battleService.isLoading()">Loading....</div>
        <div v-else class="row">
            <div class="col">
            <h1>Selected Teams:</h1>
            <table class="table">
                <thead>
                <tr>
                    <th>Attacking Team</th>
                    <th>Defending Team</th>
                    <th></th>
                </tr>
                </thead>
                <tr>
                    <td>{{ this.attackingFormation.name }}</td>
                    <td>{{ this.defendingFormation.name }}</td>
                    <td>
                        <div v-if="this.attackingFormation.name !== '' && this.defendingFormation.name !== ''">
                            <button class="btn btn-success" v-on:click="this.initiateBattle()">Start Duel</button>
                        </div>
                    </td>
                </tr>
                <tr v-if="this.battleResult !== null">
                    <td>{{ this.battleResult.time }}</td>
                    <td>{{ this.battleResult.winner }}</td>
                    <td><img v-bind:src="'https://localhost/battles/' + this.battleResult.id + '-battle.gif'"></td>
                </tr>
            </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
    data() {
        return {
            enemy: {
                username: '',
                hero: [],
                battleFormations: []
            },
            defendingFormation: {
                name:''
            },
            attackingFormation: {
                name:''
            },
            battleResult: null
        }
    },
    methods:{
        async refresh(){
            this.enemy = await this.userService.get(this.playerId)
            this.enemy.battleFormations = await this.battleFormationService.search({
                'user.username': this.enemy.username
            })
            this.app_auth.user.battleFormations = await this.battleFormationService.search({
                'user.username': this.app_auth.username
            })
        },
        async initiateBattle(){
            let result = await this.battleService.create({
                'battleType' : this.attackingFormation.battleType['@id'],
                'battleFormationA' : this.attackingFormation['@id'],
                'battleFormationB' : this.defendingFormation['@id'],
            })
            this.battleResult = result.data
        },
        filteredBattleFormation(battleFormations, battleTypeId){
            return battleFormations.filter(bf => bf.battleType.id === battleTypeId)
        },
    },
    components: {
    },
    props:['playerId'],
    mounted(){
        this.refresh()
    },
    computed: {
        ...mapState([
            'userService',
            'battleService',
            'battleFormationService',
            'app_auth'
        ]),
    },
}
</script>

<style>

</style>
