<template>
    <div class="zone-container bg-dark"
         ref="map"
         v-on:touchstart="dragStart"
         v-on:touchmove="dragMove"
         v-on:touchend="dragStop"
         v-on:mousedown="dragStart"
         v-on:mousemove="dragMove"
         v-on:mouseup="dragStop"
    >
        <div id="map-container">
            <div :style="{'margin-left': -(this.mapView.xOffset % 85) + 'px', 'margin-top': -(this.mapView.yOffset % 85) + 'px'}">
                <div v-for="y in this.mapViewYRange" :key="y" class="zone-row">
                    <div v-for="x in this.mapViewXRange"
                         :key="x"
                         class="zone-box"
                         :x = "x"
                         :y = "y"
                    >
                        <ZoneCoordinate v-if="this.zone.byIndex[convertToIndex(x,y, this.zone.width)]"
                                        :coordinate-position="convertToIndex(x,y, this.zone.width)"
                                        :selected-position="selectedPosition"
                                        :zone-coordinate="this.zone.byIndex[convertToIndex(x,y, this.zone.width)]"
                                        v-on:selected="selectZoneCoordinate"
                        >
                        </ZoneCoordinate>
                    </div>
                </div>
                <template v-for="hero in this.app_auth.user.heroes" :key="hero">
                    <div v-if="isTravelling(hero)">
                        <Sprite style="position:absolute;" :style="{'top': this.calculateHeroOffset(hero)[1] + 'px', 'left': this.calculateHeroOffset(hero)[0] + 'px'}" :type="hero.heroType.name" :direction="1" :action="1" :loop="true"/>
                        <div>{{hero.id}}</div>
                    </div>
                </template>
            </div>
        </div>
        <Modal
            v-if="selectedPosition !== null && selectedZoneCoordinate !== null"
            id="popup"
            :title="'Coordinate (' + selectedZoneCoordinate.x + ', ' + selectedZoneCoordinate.y + ')'"
            v-on:closed="selectedPosition = null"
            :center-button="{'text': 'Examine', 'to': {name: 'viewZoneCoordinate', params: {'zoneCoordinateId': selectedZoneCoordinate.id}}}"
            close-button="true"
        >
            <div class="d-flex">
                <div style="padding:0 10px">
                    <ZoneCoordinate
                        :coordinate-position="-1"
                        :selected-position="0"
                        :zone-coordinate="this.selectedZoneCoordinate">
                    </ZoneCoordinate>
                </div>
                <table class="table">
                    <tr>
                        <th>Level</th>
                        <td>{{ selectedZoneCoordinate.level }}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td>{{ selectedZoneCoordinate.type }}</td>
                    </tr>
                    <tr v-if="selectedZoneCoordinate.claimedBy">
                        <th>Claimed By</th>
                        <td>{{ selectedZoneCoordinate.claimedBy.username }}</td>
                    </tr>
                    <tr v-if="selectedZoneCoordinate.defendedBy">
                        <th>Defended By</th>
                        <td><span v-for="d in selectedZoneCoordinate.defendedBy" :key="d">{{ d.user.username }}</span></td>
                    </tr>
                </table>

            </div>


        </Modal>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import ZoneCoordinate from "./widgets/ZoneCoordinate";
import Modal from "./widgets/Modal"
import Sprite from "./widgets/Sprite"


export default {
    data() {
        return {
            selectedPosition: null,
            battleFormations:[],
            blockSize: {
                x: 10,
                y: 10
            },
            loadedBlocks:{

            },
            mapViewDims: {
                width: 0,
                height: 0
            },
            mapView: {
                left: 0,
                top: 0,
                xOffset: 0,
                yOffset: 0
            },
            lastBattle:{
                data: {
                    battle: null
                }
            },
            dragInfo:{
                left: 0,
                top: 0,
                x: 0,
                y:0,
                which: 0,
                dragging: false,
                startXOffset: 0,
                startYOffset: 0
            }
        }
    },
    components: {
        ZoneCoordinate, Modal, Sprite
    },
    methods:{
        convertGridToAbsolutePixelsX(gridX){
            return -this.mapView.xOffset + gridX * 85
        },
        convertGridToAbsolutePixelsY(gridY){
            return -this.mapView.yOffset + gridY * 85
        },
        calculateHeroOffset(hero){
            if(hero.walkLine.length > 0) {
                // let v = [hero.walkLine[3] - hero.walkLine[1], hero.walkLine[2] - hero.walkLine[0]]
                return [this.convertGridToAbsolutePixelsX(hero.walkLine[0]), this.convertGridToAbsolutePixelsY(hero.walkLine[1])]
            }
            return []
        },
        isTravelling(hero){
            return (hero.walkLine.length > 0)
        },
        async dragStop(){
            this.dragInfo.dragging = false
            this.zoneView.left = this.$refs['map'].scrollLeft
            this.zoneView.top = this.$refs['map'].scrollTop
        },
        async dragStart(evt){


                this.dragInfo.left = this.$refs['map'].scrollLeft
                this.dragInfo.top = this.$refs['map'].scrollTop
            if('clientX' in evt) {
                this.dragInfo.x = evt.clientX
                this.dragInfo.y = evt.clientY
            }
            else{
                this.dragInfo.x = evt.touches[0].clientX
                this.dragInfo.y = evt.touches[0].clientY
            }
                this.dragInfo.dragging = true
                this.dragInfo.startXOffset = this.mapView.xOffset
                this.dragInfo.startYOffset = this.mapView.yOffset
                this.which = evt.which

        },
        async dragMove(evt){
            if(this.dragInfo.dragging) {
                if(evt.which === this.which) {
                    // How far the mouse has been moved
                    let dx = 0
                    let dy = 0
                    if('clientX' in evt) {
                        dx = evt.clientX - this.dragInfo.x;
                        dy = evt.clientY - this.dragInfo.y;
                    }
                    else{
                        dx = evt.touches[0].clientX - this.dragInfo.x;
                        dy = evt.touches[0].clientY - this.dragInfo.y;
                    }



                    // Scroll the element
                    this.mapView.xOffset = Math.min(Math.max(-1,this.dragInfo.startXOffset - dx), (this.zone.width - this.mapViewDims.width + 1) * 85)
                    this.mapView.yOffset = Math.min(Math.max(-1,this.dragInfo.startYOffset - dy), (this.zone.height - this.mapViewDims.height + 1) * 85)
                    this.mapView.left = Math.floor(this.mapView.xOffset / 85);
                    this.mapView.top = Math.floor(this.mapView.yOffset / 85);
                    this.loadVisibleZoneRegions()
                }
                else{
                    this.dragStop()
                }
            }
        },
        async selectZoneCoordinate(newPosition){
            this.selectedPosition = newPosition
        },
        async attackZone(battleFormationIri){
            this.lastBattle = await this.zoneCoordinateService.postItemAction(this.selectedZoneCoordinate.id, 'attack', {
                'battleFormation' : battleFormationIri
            })
            this.zone = await this.zoneService.get(1)
        },
        loadVisibleZoneRegions(){
            let bufferBorder = 5
            for(let x = this.mapView.left; x < this.mapView.left + this.mapViewDims.width * 2; x+= this.blockSize.x){
                for(let y = this.mapView.top - bufferBorder; y < this.mapView.top + this.mapViewDims.height * 2; y+= this.blockSize.y){
                    this.loadZoneRegion(Math.floor(x / this.blockSize.x), Math.floor(y / this.blockSize.y))
                }
            }
        },
        async loadZoneRegion(blockX, blockY){
            let cacheName = blockX+'_'+blockY
            if(cacheName in this.loadedBlocks){
                if(this.loadedBlocks[cacheName] === true){
                    return
                }
            }
            this.loadedBlocks[cacheName] = true

            let minx = blockX * this.blockSize.x
            let maxx = minx + this.blockSize.x
            let miny = blockY * this.blockSize.y
            let maxy = miny + this.blockSize.y
            let result = await this.zoneService.search({
                'x[gte]' : minx,
                'x[lt]' : maxx,
                'y[gte]' : miny,
                'y[lt]' : maxy,
            },{
                subResource: '/1/zone_coordinates'
            })

            for(let i = 0; i < result.data['hydra:member'].length; i++){
                this.zone.byIndex[this.convertToIndex(result.data['hydra:member'][i].x, result.data['hydra:member'][i].y, this.zone.width)] = result.data['hydra:member'][i]
            }

        },
        async loadZone(){
            let result = await this.zoneService.get(1)
            result.byIndex = {}
            this.loadedBlocks = {}
            if(this.zone === null) {
                this.zone = result
            }
            else{
                result.byIndex = this.zone.byIndex
                this.zone = result
            }
            this.battleFormations = await this.battleFormationService.search({
                'user.username': this.app_auth.username
            })
            await this.loadVisibleZoneRegions()
            await this.updateScrollPosition()
        },
        async updateScrollPosition(){
            this.$refs['map'].scrollTop = this.zoneView.top
            this.$refs['map'].scrollLeft = this.zoneView.left
            console.log("scrolled to " + this.zoneView.top)
            this.$forceUpdate()
        },
        convertToIndex(x,y, width){
            return (y) * width + (x)
        },
        range(size, startAt = 0) {
            return [...Array(size).keys()].map(i => i + startAt);
        },
        updateMapViewDims(){
            this.mapViewDims = this.calculateCoordinateWidth()
        },
        calculateCoordinateWidth(){
            if(this.$refs['map']) {
                return {
                    width: Math.ceil(this.$refs['map'].clientWidth / 85)+1,
                    height: Math.ceil(this.$refs['map'].clientHeight / 85)+1
                }
            }
            return {
                width: 0,
                height: 0
            }
        }
    },
    props:[],
    mounted() {

    },
    activated() {
       this.updateMapViewDims()
        this.updateScrollPosition()
        this.loadZone()
    },
    created() {
        window.addEventListener("resize", this.updateMapViewDims);
    },
    unmounted() {
        window.removeEventListener("resize", this.updateMapViewDims);
    },
    computed: {
        mapViewXRange(){
            let min = Math.max(0,this.mapView.left)
            let extra = Math.max(0,this.mapView.left + this.mapViewDims.width - this.zone.width)
            return this.range(this.mapViewDims.width - extra, min)
        },
        mapViewYRange(){
            let min = Math.max(0,this.mapView.top)
            let extra = Math.max(0,this.mapView.top + this.mapViewDims.height - this.zone.height)
            return this.range(this.mapViewDims.height - extra, min)
        },
        selectedZoneCoordinate(){
            if(this.selectedPosition in this.zone.byIndex){
                return this.zone.byIndex[this.selectedPosition]
            }
            return null
        },
        ...mapFields([
            'zoneService',
            'zoneCoordinateService',
            'battleFormationService',
            'battleService',
            'app_auth',
            'zone',
            'zoneView'
        ])
    },
}
</script>

<style scoped>
.zone-container{
    overflow: hidden;
    user-select: none;
    overscroll-behavior: contain;
    display: flex;
    flex-direction: row;
    grid-area: fullcontent;
}
#map-container{
    overflow: hidden;
}
#popup{
    min-width: 350px;
    height: auto;
}
@media(max-width: 768px){
    .zone-container{
      flex-direction: column;
    }
    #popup{
        height: min-content;
        width: auto;
    }
}

.zone-row{
    display: block;
    margin: 0;
    padding: 0;
    height: 85px;
    white-space: nowrap;
    float:left;
    clear:left;
}
.zone-box{
    width: 85px;
    height: 95px;
    display: inline-block;
    line-height: 95px;
    position: relative;
}
</style>
