let tiles = {
  'clear': {
    id: 'clear',
    class: 'clear'
  },
  1001:{
    id: 1001,
    class: 'road-1',
  },
  1002: {
    id: 1002,
    class: 'road-2'
  },
  1003: {
    id: 1003,
    class: 'road-3'
  },
  1004: {
    id: 1004,
    class: 'road-4'
  },
  1005: {
    id: 1005,
    class: 'road-5'
  },
  1006: {
    id: 1006,
    class: 'road-6'
  },
  1007: {
    id: 1007,
    class: 'road-7'
  },
  1008: {
    id: 1008,
    class: 'road-8'
  },
  1009: {
    id: 1009,
    class: 'road-9'
  },
  1010: {
    id: 1010,
    class: 'road-10'
  },
  1011: {
    id: 1011,
    class: 'road-11'
  },
  1012: {
    id: 1012,
    class: 'road-12'
  },
  1013: {
    id: 1013,
    class: 'road-13'
  }
}
for (const key of Object.keys(tiles)) {
  tiles[key].type = 'road'
}
export default tiles
