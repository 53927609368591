import {watch} from 'vue'

export default(store, service, filters) => {


  let svc = store.getters.getField(service)
  let records = []
  let f = {}


  for(const key in filters){
    watch(
      () => store.getters.getField(filters[key]), (newValue)=> {
        f[key] = newValue
        updateRecords()
      }
    )
  }




  function updateRecords(){
    records = svc.search(f)
    console.log(records)
  }

  return {
    getRecords: function(){
      return records;
    }
  }
}
