<template>
    <MainContainer></MainContainer>
</template>

<script>
import MainContainer from "./components/MainContainer";
import {mapFields} from "vuex-map-fields";

export default {
    name: 'App',
    components: {
        MainContainer,
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapFields([
            'app_auth',
        ]),
    },
    watch: {
        'app_auth.is_authenticated': function(){
            if(this.$route.meta.requiresAuth === true){
                if(this.app_auth.is_authenticated !== true){
                    this.$router.push({path: 'login'})
                }
            }
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
