<template>
    <div id="zone-coordinate-container">

            <BattleEnvironment
                :battle-environment="this.zoneCoordinate.battleEnvironment"
                v-on:coordinateClicked="coordinateClickedHandler"
                :selected-coordinate="this.selectedCoordinate"
                :current-user="this.app_auth.user['@id']"
                v-on:heroMoved="heroMovedHandler"
                v-on:heroSelected="inspectHero"
                :zone-coordinate="this.zoneCoordinate"
            >
            </BattleEnvironment>


        <div id="zone-coordinate-popups">
            <Modal
                :title="'Zone Coordinate (' + this.zoneCoordinate.x + ', '+this.zoneCoordinate.y +')'"
                close-button="true"
                v-on:closed="this.$router.go(-1)"
            >
                <div id="popup-layout">
                    <ul class="nav nav-tabs" style="grid-area: tabs">
                        <template v-for="t in this.tabChoices" :key="t">
                            <li  class="nav-item" v-if="this.tabConditions[t]()">
                            <button class="game-button" :class="{'active': this.activeTab === t}" v-on:click="this.activeTab = t">{{ t }}</button>
                            </li>
                        </template>
                    </ul>
                    <div class="game-tab-container p-2">
                        <table v-if="this.activeTab === this.tabChoices.STATUS" class="table table-condensed m-0">
                            <tr>
                                <th>Level</th>
                                <td>{{ this.zoneCoordinate.level }}</td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>{{ this.zoneCoordinate.type }}</td>
                            </tr>
                            <tr>
                                <th>Claimed By</th>
                                <td v-if="this.zoneCoordinate.claimedBy">{{ this.zoneCoordinate.claimedBy.username }}</td>
                                <td v-else></td>
                            </tr>
                            <tr>
                                <th>Defended By</th>
                                <td v-if="this.zoneCoordinate.defendedBy"><span v-for="d in this.zoneCoordinate.defendedBy" :key="d">{{ d.user.username }}</span></td>
                            </tr>
                        </table>
                        <div v-if="this.activeTab === this.tabChoices.ACTIONS" id="actions-container">
                            <button v-if="mode ==='V'" type="button" class="btn game-button dark w-100 mt-3" v-on:click="this.attackZone()">
                                Attack
                            </button>
                        </div>

                        <div v-if="this.activeTab === this.tabChoices.BATTLES" style="position:absolute; top: 0; left: 0; width: 100%">
                            <router-link v-for="battleIri in this.zoneCoordinate.battles" :key="battleIri" class="btn game-button dark w-100 mb-3" :to="{name: 'viewBattle', params: {'battleId': battleIri}}">View Battle</router-link>
                        </div>

                        <div v-if="this.activeTab === this.tabChoices.HEROES" style="position:absolute; top: 0; left: 0; width: 100%" class="p-2">
                            <h3 class="game-tab-title ps-2 pt-1">Available</h3>
                            <template v-for="hero in this.app_auth.user.heroes"
                                    :key="hero"
                            >
                                <div
                                    v-if="this.zoneCoordinate['@id'] === hero.zoneCoordinate  && this.displayTravelCountdown(hero) === null"
                                     class=" mt-1 w-100"
                                    :class="{'dark' : this.zoneCoordinate['@id'] === hero.zoneCoordinate}"
                                >
                                    <div class="row">
                                        <div class="col-2">
                                            <Sprite style="position:relative; margin-top: 0" :type="hero.heroType.name" :direction="1" :action="1" :loop="true"/>
                                        </div>
                                        <div class="col-9">
                                            <h5 class="card-title">{{ hero.heroType.name }} ({{ hero.id }})</h5>
                                            <div class="d-flex flex-row flex-wrap justify-content-between text-muted">
                                                <h6 class="card-subtitle">Level: {{ hero.level }}</h6>
                                                <h6 class="card-subtitle">Exp: {{ hero.exp }}</h6>
                                                <h6 class="card-subtitle">Attack: {{ hero.physicalAttack + hero.magicalAttack }}</h6>
                                                <h6 class="card-subtitle">Defense: {{ hero.physicalDefense + hero.magicalDefense }}</h6>
                                                <h6 class="card-subtitle">Atk Speed: {{ hero.heroType.attackSpeed }}</h6>
                                            </div>
                                            <button v-if="this.selectedCoordinate !== null" class="game-button dark" v-on:click="heroSelected(hero.id)" type="button">Set Position</button>
                                            <button v-else class="game-button light" >Set Position</button>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            </template>

                            <h3 class="game-tab-title ps-2 pt-1">Approaching</h3>
                            <template v-for="hero in this.app_auth.user.heroes"
                                      :key="hero"
                            >
                                <div
                                    v-if="this.zoneCoordinate['@id'] === hero.zoneCoordinate && this.displayTravelCountdown(hero) !== null"
                                    class=" mt-1 w-100"
                                    :class="{'dark' : this.zoneCoordinate['@id'] === hero.zoneCoordinate}"
                                >
                                    <div class="row">
                                        <div class="col-2">
                                            <Sprite style="position:relative; margin-top: 0" :type="hero.heroType.name" :direction="1" :action="1" :loop="true"/>
                                        </div>
                                        <div class="col-9">
                                            <h5 class="card-title">{{ hero.heroType.name }} ({{ hero.id }})</h5>
                                            <p>{{ this.displayTravelCountdown(hero) }}</p>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            </template>

                            <h3 class="game-tab-title ps-2 pt-1">Other</h3>
                            <template v-for="hero in this.app_auth.user.heroes"
                                      :key="hero"
                            >
                                <div
                                    v-if="this.zoneCoordinate['@id'] !== hero.zoneCoordinate"
                                    class=" mt-1 w-100"
                                >
                                    <div class="row">
                                        <div class="col-2">
                                            <Sprite style="position:relative; margin-top: 0" :type="hero.heroType.name" :direction="1" :action="1" :loop="true"/>
                                        </div>
                                        <div class="col-9">
                                            <h5 class="card-title">{{ hero.heroType.name }} ({{ hero.id }})</h5>
                                            <div class="d-flex flex-row flex-wrap justify-content-between text-muted">
                                                <h6 class="card-subtitle">Level: {{ hero.level }}</h6>
                                                <h6 class="card-subtitle">Exp: {{ hero.exp }}</h6>
                                                <h6 class="card-subtitle">Attack: {{ hero.physicalAttack + hero.magicalAttack }}</h6>
                                                <h6 class="card-subtitle">Defense: {{ hero.physicalDefense + hero.magicalDefense }}</h6>
                                                <h6 class="card-subtitle">Atk Speed: {{ hero.heroType.attackSpeed }}</h6>
                                            </div>
                                            <p>{{ this.displayTravelCountdown(hero) }}</p>
                                            <button class="game-button dark" v-on:click="moveHeroToZone(hero)" type="button">Travel Here</button>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            </template>
                        </div>

                        <div v-if="this.activeTab === this.tabChoices.INSPECTOR" style="position:absolute; top: 0; left: 0; width: 100%">
                            <div class="row " v-if="selectedHero !== null && selectedHero !== undefined">
                                <div class="alert" :class="{'alert-danger': this.selectedHeroAllianceChoice === this.allianceChoices.ENEMY, 'alert-success': this.selectedHeroAllianceChoice === this.allianceChoices.OWNED}">
                                    <label>Status:</label> {{ selectedHeroAllianceChoice }}
                                </div>
                                <div class="p-3 d-flex">
                                    <div style="padding:0 10px">
                                        <Sprite style="position:relative; margin-top: 0" :type="selectedHero.heroType.name" :direction="1" :action="1" :loop="true"/>
                                        <table class="table">
                                            <tr>
                                                <th>Level</th>
                                                <td>{{ selectedHero.level }}</td>
                                            </tr>
                                            <tr>
                                                <th>Exp</th>
                                                <td style="white-space: nowrap">{{ selectedHero.exp }} / {{ selectedHero.requiredExp}}</td>
                                            </tr>
                                            <tr>
                                                <th>Location</th>
                                                <td>{{ selectedHero.zoneCoordinatePosition }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <table class="table">
                                        <tr>
                                            <th>Type</th>
                                            <td>{{ selectedHero.heroType.name }}</td>
                                        </tr>
                                        <tr>
                                            <th>HP</th>
                                            <td>{{ selectedHero.hp }}</td>
                                        </tr>
                                        <tr>
                                            <th>Physical Attack</th>
                                            <td>{{ selectedHero.physicalAttack }}</td>
                                        </tr>
                                        <tr>
                                            <th>Magical Attack</th>
                                            <td>{{ selectedHero.magicalAttack }}</td>
                                        </tr>
                                        <tr>
                                            <th>Physical Defense</th>
                                            <td>{{ selectedHero.physicalDefense }}</td>
                                        </tr>
                                        <tr>
                                            <th>Magical Defense</th>
                                            <td>{{selectedHero.magicalDefense }}</td>
                                        </tr>
                                        <tr>
                                            <th>Atk Speed</th>
                                            <td>{{ selectedHero.heroType.attackSpeed }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div v-if="this.activeTab === this.tabChoices.EDITOR" style="position:absolute; top: 0; left: 0; width: 100%" class="p-2">
                            <button class="game-button dark mb-2" v-on:click="saveMap">Save</button>
                            <h3 class="game-tab-title ps-2 pt-1">Floor</h3>
                            <div class="d-flex flex-wrap mb-2">
                                <EnvironmentTile
                                    v-for="item in this.floorTiles"
                                    class="m-1 selectable"
                                    :key="item.id"
                                    :tile="item.id"
                                    :class="{'selected' : this.editorSelectedTile === item}"
                                    v-on:click="this.editorSelectedTile !== item ? this.editorSelectedTile = item : this.editorSelectedTile = null"
                                />
                            </div>

                            <h3 class="game-tab-title ps-2 pt-1">Road</h3>
                            <div class="d-flex flex-wrap mb-2">
                                <EnvironmentTile
                                    v-for="item in this.roadTiles"
                                    class="m-1 selectable"
                                    :key="item.id"
                                    :tile="item.id"
                                    :class="{'selected' : this.editorSelectedTile === item}"
                                    v-on:click="this.editorSelectedTile = item"
                                />
                            </div>
                            <h3 class="game-tab-title ps-2 pt-1">Environment</h3>
                            <div class="d-flex flex-wrap mb-2">
                                <EnvironmentTile
                                    v-for="item in this.environmentTiles"
                                    class="m-1 selectable"
                                    :key="item.id"
                                    :tile="item.id"
                                    :class="{'selected' : this.editorSelectedTile === item}"
                                    v-on:click="this.editorSelectedTile = item"
                                />
                            </div>
                        </div>
                    </div>

                    <router-link style="grid-area: tabfooter" class=" w-100 mt-3 btn game-button dark" v-if="this.zoneCoordinate.id" to="/zone">Back To Map</router-link>
                </div>
            </Modal>


        </div>
    </div>



</template>

<script>
import {mapFields} from "vuex-map-fields";
import BattleEnvironment from "./widgets/BattleEnvironment"
import Modal from "./widgets/Modal"
import Sprite from "./widgets/Sprite"
import floorTiles from "../enums/floorTiles"
import roadTiles from "../enums/roadTiles"
import environmentTiles from "../enums/environmentTiles";
import EnvironmentTile from "./widgets/EnvironmentTile";


export default {
    data() {
        return {
            mode: 'V',
            countdownRef: null,
            currentTimeSeconds: Math.floor(new Date().getTime()/1000),
            tabChoices: {
                STATUS : 'Status',
                INSPECTOR: 'Inspector',
                HEROES: 'Heroes',
                ACTIONS: 'Actions',
                BATTLES: 'Battles',
                EDITOR: 'Editor'
            },
            tabConditions: {
                'Status': ()=>true,
                'Inspector': ()=>true,
                'Battles': ()=>true,
                'Heroes': ()=>this.app_auth.is_authenticated,
                'Actions': ()=>this.app_auth.is_authenticated,
                'Editor': ()=>this.isZoneCoordinateOwner,
            },
            editorSelectedTile : null,
            floorTiles : floorTiles,
            roadTiles: roadTiles,
            environmentTiles: environmentTiles,
            allianceChoices: {
                OWNED: 'Owned',
                FRIEND: 'Friend',
                ENEMY: 'Enemy'
            },
            activeTab : 'Status',
            zoneCoordinate:{
                id: null,
                x: null,
                y: null,
                battleEnvironment:{}
            },
            selectedCoordinate: null,
            selectedHero: null,
            is_loading: 0
        }
    },
    components: {
        BattleEnvironment, Modal, Sprite, EnvironmentTile
    },
    methods:{
        async saveMap(){
        //    let floor = Object.values(this.zoneCoordinate.floor)
            this.zoneCoordinateService.postItemAction(this.zoneCoordinate.id,'savemap',{
                'floor' : this.zoneCoordinate.floor,
                'roads' : this.zoneCoordinate.roads,
                'environment' : this.zoneCoordinate.environment
            })
        },
        displayTravelCountdown(hero){
            if(hero.arrivalTime === null){
                return null
            }
            let d = Math.floor((new Date(hero.arrivalTime)).getTime()/1000)
            let result = d - this.currentTimeSeconds
            if(result < 0){
                return null
            }
            return result + 's'
        },
        async inspectHero(bhi){
            let hero = null
            if(bhi !== null){
                hero = bhi.hero
            }

            if(this.selectedHero !== hero){
                this.selectedHero = hero
                if(this.selectedHero !== null){
                    this.activeTab = this.tabChoices.INSPECTOR
                }
            }
        },
        async moveHeroToZone(hero){
            this.is_loading++;
            try {
                let result = await this.heroService.postItemAction(hero.id,'move',{'targetZoneCoordinate' : this.zoneCoordinate['@id']})
                this.updateHeroCache(result.data)
            }

            finally{
                this.is_loading--;
            }
        },
        updateHeroCache(hero){
            for(let i = 0; i < this.app_auth.user.heroes.length; i++) {
                if (this.app_auth.user.heroes[i].id === hero.id) {
                    this.app_auth.user.heroes[i] = hero
                }
            }
        },
        async heroSelected(id){
            for(let i = 0; i < this.app_auth.user.heroes.length; i++){
                if(this.app_auth.user.heroes[i].id === id){
                    this.app_auth.user.heroes[i].zoneCoordinate = this.zoneCoordinate['@id']
                    this.app_auth.user.heroes[i].zoneCoordinatePosition = this.selectedCoordinate
                    await this.heroService.update(this.app_auth.user.heroes[i])
                    await this.loadZoneCoordinate()
                }
            }
            this.mode = 'V'
        },
        async heroMovedHandler(oldPosition, newPosition){

                for(let i = 0; i <  this.app_auth.user.heroes.length; i++){
                    if( this.app_auth.user.heroes[i].zoneCoordinatePosition === oldPosition &&
                    this.app_auth.user.heroes[i].zoneCoordinate === this.zoneCoordinate['@id']){
                        this.app_auth.user.heroes[i].zoneCoordinatePosition = newPosition
                        await this.heroService.update(this.app_auth.user.heroes[i])
                        await this.loadZoneCoordinate()
                    }
                }

        },
        async attackZone(){
            this.lastBattle = await this.zoneCoordinateService.postItemAction(this.zoneCoordinate.id, 'attack', {
            })
            await this.loadZoneCoordinate()
        },
        async loadZoneCoordinate(){
            this.zoneCoordinate = await this.zoneCoordinateService.get(this.zoneCoordinateId)
            this.mode = 'V'
        },
        coordinateClickedHandler(position){
            this.selectedCoordinate = position
            if(this.editorSelectedTile !== null){
                if(this.editorSelectedTile.type === 'road'){
                    if(this.editorSelectedTile.id === 'clear'){
                        this.zoneCoordinate.roads[position] = null
                    }
                    else {
                        this.zoneCoordinate.roads[position] = this.editorSelectedTile.id
                    }
                }
                else if(this.editorSelectedTile.type === 'environment'){
                    if(this.editorSelectedTile.id === 'clear'){
                        this.zoneCoordinate.environment[position] = null
                    }
                    else{
                        this.zoneCoordinate.environment[position] = this.editorSelectedTile.id
                    }
                }
                else if(this.editorSelectedTile.type === 'floor'){
                    if(this.editorSelectedTile.id === 'clear'){
                        this.zoneCoordinate.floor[position] = null
                    }
                    else{
                        this.zoneCoordinate.floor[position] = this.editorSelectedTile.id
                    }
                }
            }
        }
    },
    props:['zoneCoordinateId'],
    created() {
        this.countdownInterval = window.setInterval(()=>{
            this.currentTimeSeconds = Math.floor(new Date().getTime()/1000)
        }, 1000)
    },
    activated() {
        this.mode = 'V'
        this.loadZoneCoordinate()

    },
    computed: {
        isZoneCoordinateOwner(){
            if(this.zoneCoordinate.id !== null && this.app_auth.is_authenticated) {
                if (this.app_auth.user.id === this.zoneCoordinate.claimedBy.id) {
                    return true
                }
            }
            return false
        },
        selectedHeroAllianceChoice(){
            if(this.selectedHero.user.username === this.app_auth.username){
                return this.allianceChoices.OWNED
            }
            return this.allianceChoices.ENEMY
        },
        ...mapFields([
            'zoneService',
            'zoneCoordinateService',
            'battleFormationService',
            'heroService',
            'battleService',
            'app_auth'
        ])
    },
}
</script>

<style scoped>
#zone-coordinate-container{
    display: grid;
    grid-area: fullcontent;
    grid-template-columns: [first] 1fr [second] 400px [end];
    grid-template-rows: [first] 1fr [end];
    width: 100%;
}

#zone-coordinate-popups{
    grid-column: second/end;
    grid-row: first/end;
}

#popup-layout{
    display: grid;
    grid-area: fullcontent;
    grid-template-columns: 1fr ;
    grid-template-rows: [first] min-content [second] 1fr [end] min-content;
    grid-template-areas: "tabs"
                         "tabcontent"
                         "tabfooter";
    height: 100%;
}
.game-tab-container{
    grid-area: tabcontent;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    min-height: 150px;
}

@media(max-width: 768px){
    #zone-coordinate-container{
        grid-template-columns: [first] 1fr [end];
        grid-template-rows: [first] 2fr [second] 1fr [end];
    }

    #zone-coordinate-popups{
        grid-row: second/end;
        grid-column: first/end;
    }
}

</style>
