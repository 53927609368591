<template>
    <div style="grid-area: fullcontent">
        <Modal
            title="Hero Detail" v-if="this.hero !== {}"
            close-button="true"
            v-on:closed="this.$router.go(-1)"
        >
            <div class="d-flex">
                <div style="padding:0 10px">
                    <Sprite style="position:relative; margin-top: 0" :type="hero.heroType.name" :direction="1" :action="1" :loop="true"/>
                    <table class="table">
                        <tr>
                            <th>Level</th>
                            <td>{{ hero.level }}</td>
                        </tr>
                        <tr>
                            <th>Exp</th>
                            <td style="white-space: nowrap">{{ hero.exp }} / {{ hero.requiredExp}}</td>
                        </tr>
                        <tr>
                            <th>Location</th>
                            <td>{{ hero.zoneCoordinatePosition }}</td>
                        </tr>
                    </table>
                </div>
                <table class="table">
                    <tr>
                        <th>Type</th>
                        <td>{{ hero.heroType.name }}</td>
                    </tr>
                    <tr>
                        <th>HP</th>
                        <td>{{ hero.hp }}</td>
                    </tr>
                    <tr>
                        <th>Attack</th>
                        <td>{{ hero.heroType.physicalAttack + hero.heroType.magicalAttack }}</td>
                    </tr>
                    <tr>
                        <th>Defense</th>
                        <td>{{ hero.heroType.physicalDefense + hero.heroType.magicalDefense }}</td>
                    </tr>
                    <tr>
                        <th>Atk Speed</th>
                        <td>{{ hero.heroType.attackSpeed }}</td>
                    </tr>
                </table>
            </div>
        </Modal>
    </div>

</template>

<script>
import {mapFields} from "vuex-map-fields"
import Sprite from "./widgets/Sprite"
import Modal from "./widgets/Modal"

export default {
    data() {
        return {
            loading: false,
            hero: {
                heroType:{
                    name:''
                }
            }
        }
    },
    props:['heroId'],
    methods:{
        async loadHero(){
            this.loading = true
            this.hero = await this.heroService.get(this.heroId)
            this.loading = false
        }
    },
    activated() {
        this.loadHero()
    },
    components:{
        Sprite, Modal
    },
    watch(){

    },
    computed: {

        ...mapFields([
            'app_auth',
            'flash_message',
            'is_loading_heroes',
            'heroService'
        ])
    },
}
</script>

<style>

</style>
