import baseService from "./base-service";

export default baseService('battle_formations', function(obj){
    let output = obj
    if(typeof output.battleType === 'object' && '@id' in output.battleType){
      output.battleType = output.battleType['@id']
    }

    return output

})
