<template>
    <form class="centered-form"  v-on:submit="redeemHero">
    <Modal
        title="Redeem Hero"
        :center-button="{'text': 'Redeem'}"
        close-button="true"
        v-on:closed="this.$router.go(-1)"
    >
        Enter a code to redeem a new hero.
        <div v-if="loading === false">
            <input type="text" class="form-control" v-model="redemptionCode">
        </div>
    </Modal>
    </form>
</template>
<script>

import Modal from "./widgets/Modal"
export default {
    data() {
        return {
            loading: false,
            redemptionCode: "",
        }
    },
    computed: {

    },
    mounted() {

    },
    components:{
        Modal
    },
    methods:{
        async redeemHero(){
            this.loading = true
            await this.$store.dispatch('redeemHero', this.redemptionCode)
            this.redemptionCode = ""
            this.loading = false
        },
    }
}
</script>

