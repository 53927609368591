import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import router from "./router"
import store from "./store"
import axios from "axios";
import jwtAuth from "./security/jwt-auth";
import baseCollection from "./collections/base-collection"
import anime from 'animejs/lib/anime.es.js';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT + "api/v1"
axios.defaults.headers['accept'] = 'application/ld+json'
axios.defaults.headers['content-type'] = 'application/ld+json'
axios.interceptors.request.use(
  function(config) {
    if(store.getters.getField("app_auth.is_authenticated")) {
      const token = store.getters.getField("app_auth.active_token")
      if (token) {
        if(!jwtAuth.is_expired(token.exp)) {
          config.headers["Authorization"] = token;
        }
        else{
          store.commit('setJwtToken', null)
        }
      }
    }
    return config;
    },
    function(error) {
      return Promise.reject(error);
    }
)

createApp(App)
  .use(store)
  .use(router)
  .use(anime)
  .mount('#app')

let existingToken = jwtAuth.load_token()
if(existingToken !== null){
  store.commit('setJwtToken', existingToken)
  store.dispatch('getCurrentUser')
}


 baseCollection(store, 'heroService',{username: 'app_auth.username'})

