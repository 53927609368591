<template>
    <div class="container-fluid p-4">
        <div class="row">
            <div class="col-md-12">
                <div >
                    <h1 class="">PREPARE FOR BATTLE</h1>
                    <h2>{{ this.battleType.name }}</h2>
                    <p>Select or build a battle formation!</p>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col-md-2">
                <h3>Your Formations</h3>
                <button
                    v-on:click="newBattleFormation()"
                    class="btn btn-primary p-3 m-2 w-100">
                    New Battle Formation
                </button>
                <button
                    v-for="bf in availableBattleFormations" :key="bf"
                    v-on:click="loadBattleFormation(bf)"
                    class="btn btn-primary p-3 m-2 w-100"
                    :class="{'btn-success':this.currentBattleFormation.id === bf.id}"
                >
                    {{ bf.name }}
                </button>

            </div>
            <div class="col-md-4">
                <h3>Finalize Positions</h3>
                <div class="d-flex flex-column">
                    <div v-for="y in this.battleType.height" :key="y" class="d-flex">
                        <div v-for="x in this.battleType.width"
                             :key="x" class="battle-position-box"
                             v-bind:class="{'active-box':  (convertToIndex(x,y) === selectedPosition)}"
                             v-on:click="this.selectedPosition = convertToIndex(x,y)"
                        >
                            <div v-if="convertToIndex(x,y) in this.currentBattleFormation.positionMap">
                                {{ this.getHero(this.currentBattleFormation.positionMap[convertToIndex(x,y)]).id }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-floating my-3">
                    <input type="text" v-model="currentBattleFormation.name" class="form-control" id="floatingInput" placeholder="Battle Formation">
                    <label for="floatingInput">Nickname</label>
                </div>
                <template v-if="this.battleFormationService.isLoading()">
                    Loading...
                </template>
                <template v-else>
                    <button v-on:click="saveBattleFormation()" class="btn btn-primary w-100">Save</button>
                    <button v-on:click="deleteBattleFormation" class="btn btn-danger w-100 mt-5">Delete</button>
                </template>

            </div>

            <div class="col-md-6">
                <div class="row">
                    <div v-for="hero in app_auth.user.heroes"
                         :key="hero" class="col-md-6"
                    >
                        {{ }}
                        <MiniHeroShowcase
                            v-bind:class="{
                                'active-box':  (this.currentBattleFormation.positionMap[selectedPosition] === hero['@id']),
                                'secondary-box': findHeroPosition(hero['@id']) !== null
                            }"
                            :hero="hero"
                            v-on:click="assignHero(hero['@id'])"
                        >
                        </MiniHeroShowcase>
                        <button v-if="findHeroPosition(hero['@id']) !== null" v-on:click="clearPosition(findHeroPosition(hero['@id']))">Clear</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import { reactive } from 'vue'
import MiniHeroShowcase from "./widgets/MiniHeroShowcase";
export default {
    data() {
        return {
            name: "cory",
            battleType : {
                "@id": "",
                name: "",
                summary: "",
                width: 0
            },
            currentBattleFormation:{
                "id": null,
                "battleType": "",
                "name": "Battle Formation",
                "initialPositions": [],
                "positionMap":{}
            },
            availableBattleFormations:{

            },
            selectedPosition: 1
        }
    },
    components: {
        MiniHeroShowcase,
    },
    methods:{
        newBattleFormation(){
            this.currentBattleFormation = {
                "id": null,
                "battleType": this.battleType["@id"],
                "name": "Battle Formation",
                "initialPositions": [],
                "positionMap":{}
            }
        },
        deleteBattleFormation(){
            this.battleFormationService.del(this.currentBattleFormation.id)
        },
        loadAllBattleFormations(){
            this.battleFormationService.search({battleType: this.battleTypeId, 'user.username': this.app_auth.username }).then(r=>{
                this.availableBattleFormations = reactive(r)
            })
        },
        loadBattleFormation(apiBattleFormation){
            this.currentBattleFormation = {...apiBattleFormation, positionMap: {}}

            Object.keys(apiBattleFormation.initialPositions).forEach(key => {
                this.currentBattleFormation.positionMap[apiBattleFormation.initialPositions[key].position] = apiBattleFormation.initialPositions[key]['hero']
            })
        },
        saveBattleFormation(){
            if(this.currentBattleFormation.id !== null) {
                this.battleFormationService.update(this.currentBattleFormation).then(
                    this.loadAllBattleFormations()
                )
            }
            else{
                this.battleFormationService.create(this.currentBattleFormation).then(
                    this.loadAllBattleFormations()
                )
            }
        },
        convertToIndex(x,y){
            return (y-1) * this.battleType.width + (x - 1)
        },
        getHero(heroIri){
            let f = this.app_auth.user.heroes.find(h => h['@id'] === heroIri)
            if(f !== undefined) {
                return f
            }
            return {'id': 'Not Found ' + heroIri}
        },
        findHeroPosition(heroIri){
            let result = null
            Object.keys(this.currentBattleFormation.positionMap).forEach(key => {
                if(this.currentBattleFormation.positionMap[key] === heroIri) {
                    result = key
                }
            })
            return result
        },
        clearPosition(position){
            if(position in this.currentBattleFormation.positionMap){
                for(let z = 0; z < this.currentBattleFormation.initialPositions.length; z++) {
                    if (this.currentBattleFormation.initialPositions[z].position === parseInt(position)) {
                        this.currentBattleFormation.initialPositions.splice(z,1)
                        z--
                    }
                }
                delete this.currentBattleFormation.positionMap[position]
            }
        },
        assignHero(heroIri){
            let found = false
            for(let i = 0; i < this.currentBattleFormation.initialPositions.length; i++){
                if(this.currentBattleFormation.initialPositions[i].hero === heroIri){
                    this.currentBattleFormation.initialPositions[i].position = this.selectedPosition
                    found = true
                    break;
                }
            }
            for(let z = 0; z < this.currentBattleFormation.initialPositions.length; z++) {
                if (this.currentBattleFormation.initialPositions[z].position === this.selectedPosition && this.currentBattleFormation.initialPositions[z].hero !== heroIri) {
                    this.currentBattleFormation.initialPositions.splice(z,1)
                    z--
                }
            }

            if(!found){
                this.currentBattleFormation.initialPositions.push(
                    {
                        "@type": "BattleFormationInitialPosition",
                        "hero": heroIri,
                        "position": this.selectedPosition
                    }
                )
            }

            Object.keys(this.currentBattleFormation.positionMap).forEach(key => {
                if(this.currentBattleFormation.positionMap[key] === heroIri) {
                    delete this.currentBattleFormation.positionMap[key]
                }
            })
            this.currentBattleFormation.positionMap[this.selectedPosition] = heroIri
        },
    },
    props:['battleTypeId'],
    mounted() {
        let bti = parseInt(this.battleTypeId)
        this.battleTypeService.get(bti).then(b => {
            this.battleType = b
        })

        this.loadAllBattleFormations()
    },
    computed: {
        ...mapFields([
            'battleFormationService',
            'battleTypeService',
            'heroService',
            'battle_types',
            'heroes',
            'app_auth'
        ])
    },
}
</script>

<style scoped>
    .battle-position-box{
        width: 40px;
        height: 40px;
        border: 1px solid black;
    }
    .active-box, .active-box.secondary-box{
        border: 1px solid green;
        box-shadow: 0 0 10px green inset;
    }
    .secondary-box {
        border: 1px solid gray;
        box-shadow: 0 0 10px gray inset;
    }
</style>
