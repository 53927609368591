<template>
    <div class="menu-bar" :class="{'vertical': vertical}">
        <slot></slot>
    </div>
</template>

<script>

  export default {
      data() {
          return {
          }
      },
      props:['vertical']
}
</script>

<style>
.menu-bar{
    /*box-shadow: 0 0 2px var(--color-primary-2) inset;*/
    /*border: 2px solid var(--color-primary-1);*/
    background: var(--color-primary-2);
    z-index: 1000;
    font-family: 'Teko', sans-serif;
    font-size: 22px;
    display: flex;
}

.menu-bar button{
    margin: 10px -18px -25px -18px;
    text-align: center;
}
.menu-bar .dark:hover{
    background: var(--color-primary-5);
    cursor: pointer;
}
.menu-bar .dark:active{
    background: var(--color-primary-1);
}

.menu-bar .light:hover{
    background: var(--color-primary-4);
    cursor: pointer;
}
.menu-bar .light:active{
    background: var(--color-primary-3);
}

.menu-bar .active{
    box-shadow: 0 0 2px var(--color-primary-2) inset!important;
    border: 2px solid var(--color-primary-2)!important;;
    outline: 1px solid var(--color-primary-1)!important;;
    background: var(--color-primary-1)!important;;
    color: var(--color-primary-4)!important;;
}

.menu-bar .light{
    box-shadow: 0 0 2px var(--color-primary-5) inset;
    border: 2px solid var(--color-primary-2);
    outline: 1px solid var(--color-primary-1);
    background: var(--color-primary-3);
    color: var(--color-primary-1);
}

.menu-bar .dark{
    box-shadow: 0 0 2px var(--color-primary-2) inset;
    outline: 1px solid var(--color-primary-2);
    border: 2px solid var(--color-primary-1);
    background: var(--color-primary-1);
    color: var(--color-primary-4);
}

.vertical.menu-bar{
    flex-direction: column;
}

.vertical.menu-bar button, .vertical.menu-bar a{
    flex: 0 0 0px;
}

.menu-bar button, .menu-bar a{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    padding: 10px 15px;
    margin: 5px;
    line-height: 22px;
    cursor: pointer;
    flex: 1 1 0px;
}
.menu-bar button.end-button{
    padding: 5px 5px;
    margin-left: -10px;
    border-radius: 50px;
    text-align: center;
}
</style>
