<template>
    <div id="leaderboard-container">
        <div class="container">
            <div class="row text-light mt-3" style="row-gap: 30px">
                <div class="col-md-4" v-for="lb in leaderboards" :key="lb">
                    <Modal
                        :title="lb.name"
                        close-button=false
                    >
                        <table class="table">
                            <thead>
                            <tr>
                                <th>User</th>
                                <th>Score</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="lbe in lb.leaderBoardEntries" :key="lbe" :class="{'highlight':lbe.user.username === app_auth.username}">
                                <td><router-link :to="{name: 'profile', params: {'playerId': lbe.user.id}}">{{ lbe.user.username }}</router-link></td>
                                <td>{{ lbe.value }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Modal>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import Modal from "./widgets/Modal"
export default {
    data() {
        return {
        }
    },
    mounted(){
      this.$store.dispatch('loadLeaderboards')
    },
    components: {
        Modal
    },
    computed: {
        ...mapState([
            'app_auth',
            'leaderboards',
            'leaderboardService'
         ]),
    },
}
</script>

<style scoped>
#leaderboard-container{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    grid-area: fullcontent;
}
.highlight{
    background-color: var(--color-primary-3);
}
</style>
