<template>
    <div class="container-fluid" :style = "cssProps">
        <div class="row">
            <div class="col-md-9">
                <button type="button" v-on:click="this.timeline.play()">Play</button>
                <button type="button" v-on:click="this.timeline.pause()">Pause</button>
                <button type="button" v-on:click="this.restart()">Restart</button>
                <input type="range" min="1" :max="sliderMax" step="1" v-model="sliderPosition" v-on:input="this.timeline.seek(sliderPosition)">
                {{ sliderPosition }}
                <div class="bg-dark">
                    <div class="battlefield-container">
                        <div v-for="y in this.height" :key="y" class="zone-row">
                            <div v-for="x in this.width" :key="x" class="zone-box"
                                 v-bind:class="{
                    'water'  : this.terrain[this.toIndex(x,y, this.width)] === 'W',
                    'plains' : this.terrain[this.toIndex(x,y, this.width)] === 'P',
                    'forest' : this.terrain[this.toIndex(x,y, this.width)] === 'F',
                 }"
                            >
                                <div class="zone-item">
                                </div>
                            </div>
                        </div>
                        <Sprite
                            v-for="h in this.heroes"
                            :key="h"
                            :action="h.action"
                            :direction="h.lookdirection"
                            :team="h.team"
                            :type="h.type"
                            :id="h.id"
                            :hp="h.hp"
                            :initial-hp="h.initialHp"
                            :attack-land-speed="h.attackLandSpeed"
                            :move-speed="h.moveAnimationDuration"
                            :depth="h.y"
                            :x="h.x"
                            :y="h.y"
                            :label="h.id"
                        >
                        </Sprite>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <h2>Battle {{ this.battle.id }}</h2>
                <router-link class="btn btn-success" v-if="this.battle.zoneCoordinate" :to="{name: 'viewZoneCoordinate', params: {'zoneCoordinateId': this.battle.zoneCoordinate}}">View Coordinate</router-link>
                <h4>Battlefield Dimensions: {{ this.width }}x{{ this.height }}</h4>
                <pre>{{this.battle}}</pre>
            </div>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import anime from "animejs/lib/anime.es.js";
import {nextTick} from "vue";
import Sprite from "./widgets/Sprite"
import ACTIONS from "../enums/actions";
//import EnvironmentTile from "./widgets/EnvironmentTile";


export default {
    data() {
        return {
            battle: {
                battleEvents:[]
            },
            terrain: {},
            heroes: {},
            hTracker: {},
            width: 0,
            height: 0,
            timeline: {},
            padding: 32,
            tileSize: 64,
            spriteSize: 128,
            msPerTick: 100,
            sliderPosition: 0,
            sliderMax: 1
        }
    },
    components: {
        Sprite
    },
    methods:{
        toIndex(x,y, width){
            return (x-1) + (y-1) * width
        },
        processInitialEvent(be){
            if(be.type === "Z"){
                this.width = be.x
                this.height = be.y
            }
            if(be.type === "I"){
                this.terrain[be.x + be.y * this.width] = be.data['terrain']
            }
            if(be.type === "H"){
                this.heroes[be.srcHero] = {
                    id: be.srcHero,
                    x: be.x,
                    y: be.y,
                    lookX: be.lookX,
                    lookY: be.lookY,
                    initialHp: be.amount,
                    hp: be.amount,
                    type: be.data.name,
                    lookdirection: 0,
                    attackLandSpeed: be.data.attackLandSpeed * this.msPerTick,
                    action: ACTIONS.idle,
                    team: be.data.team,
                    attackSpeed: be.data.attackSpeed + 1,
                    moveSpeed: be.data.moveSpeed + 1,
                    moveAnimationDuration: be.data.moveSpeed * this.msPerTick
                }
                this.hTracker[be.srcHero] = {
                    x: be.x,
                    y: be.y
                }
            }
        },
        getDirectionTo(from, to){
            let direction = 0
            if(from[0] > to[0]){
                direction = 3
            }
            if(from[0] < to[0]){
                direction = 1
            }
            if(from[1] < to[1]){
                direction = 2
            }
            return direction
        },
        restart(){
            for (const [key, value] of Object.entries(this.heroes)) {
                this.heroes[key].hp = value.initialHp
                this.heroes[key].action = ACTIONS.idle
            }

            this.timeline.restart()
        },
        timelineUpdate(){
            this.sliderPosition = Math.round(this.timeline.progress / 100 * this.timeline.duration);
        },
        async loadBattle(){
            this.battle = await this.battleService.get(this.battleId)
            for(let i = 0; i < this.battle.battleData.battleEvents.length; i++){
                let be = this.battle.battleData.battleEvents[i]
                this.processInitialEvent(be)
            }
            await nextTick()
            this.timeline = anime.timeline({
                autoplay: false,
                update: this.timelineUpdate
            })

            for (const [key, value] of Object.entries(this.heroes)) {
                this.timeline.add({
                    'targets' : '#hero-' + this.heroes[key].id,
                    'left' : [0, this.padding + value.x * this.tileSize],
                    'top' : [0, this.padding + value.y * this.tileSize],
                    easing: 'linear',
                    opacity: [0,1],
                    duration: 1
                },0)
            }
            for(let ii = 0; ii < this.battle.battleData.battleEvents.length; ii++){
                let be = this.battle.battleData.battleEvents[ii]

                let t = this
                if(be.type === "M"){
                    let d = this.getDirectionTo([this.hTracker[be.srcHero].x, this.hTracker[be.srcHero].y], [be.x, be.y])
                    this.timeline.add({
                        'targets': this.heroes[be.srcHero],
                        keyframes: [
                            {
                                'lookdirection': d,
                                'action' : ACTIONS.idle,
                                duration: 25,
                                easing: 'steps(1)',
                            },
                            {
                                'lookdirection': d,
                                'action' : ACTIONS.walk,
                                duration: this.heroes[be.srcHero].moveSpeed * this.msPerTick - 25,
                                easing: 'steps(1)',
                            }
                        ],
                        duration: this.heroes[be.srcHero].moveSpeed * this.msPerTick,
                        change: function(a){
                            if(be.srcHero === 1) {
                                console.log('be.tick:' + be.tick + ' be.srcHero: ' + be.srcHero + ' Progress: ' + Math.round(a.progress) + ' ' + t.heroes[be.srcHero].action)
                            }
                        },
                    }, be.tick * this.msPerTick)

                    this.timeline.add({
                        'targets': this.heroes[be.srcHero],
                        'y' : be.y,
                        'x' : be.x,
                        easing: 'linear',
                        duration: this.heroes[be.srcHero].moveSpeed * this.msPerTick
                    }, be.tick * this.msPerTick)

                    // this.timeline.add({
                    //     'targets': this.heroes[be.srcHero],
                    //     'lookdirection': d,
                    //     'action' : [ACTIONS.walk, ACTIONS.idle],
                    //     duration: 2,
                    //     easing: 'steps(2)'
                    // }, be.tick * this.msPerTick + this.heroes[be.srcHero].moveSpeed * this.msPerTick)

                    this.hTracker[be.srcHero].x = be.x
                    this.hTracker[be.srcHero].y = be.y
                    if(be.srcHero === 1) {
                        console.log("Start Walk: " + be.tick * this.msPerTick + ' End Walk: ' + (be.tick * this.msPerTick + this.heroes[be.srcHero].moveSpeed * this.msPerTick))
                    }
                }

                if(be.type === "A"){
                    this.timeline.add({
                        'targets': this.heroes[be.srcHero],
                        'action' : [ACTIONS.attack, ACTIONS.idle],
                        duration: 1
                    }, be.tick * this.msPerTick)
                }

                if(be.type === "S"){
                    let d = this.getDirectionTo([be.x, be.y], [be.lookX, be.lookY])
                    let startTick = be.tick * this.msPerTick
                    let endTick = (be.tick + be.data.dmgDelay) * this.msPerTick
                    let releaseTick = 0
                    let animation = ACTIONS.attack
                    if(be.data.ranged) {
                        startTick = (be.tick - be.data.dmgDelay/2) * this.msPerTick
                        releaseTick = (be.tick + be.data.dmgDelay/2) * this.msPerTick
                        endTick = (be.tick + be.data.dmgDelay) * this.msPerTick
                        animation = ACTIONS.shoot
                    }
                    this.timeline.add({
                        'targets': this.heroes[be.srcHero],
                        'lookdirection': d,
                        'action' : [ACTIONS.idle, animation],
                        duration: 1
                    }, startTick)


                    if(be.data.ranged) {
                        let ox = be.lookX - be.x
                        let oy = be.lookY - be.y
                        let rad = 0;
                        let rox = ox * this.tileSize
                        let roy = oy * this.tileSize
                        if (rox !== 0) {
                            rad = Math.atan(roy / rox)
                        }
                        else{
                            if(roy > 0){
                                rad = 3.141/2
                            }
                            else{
                                rad = -3.141/2
                            }
                        }

                        this.timeline.add({
                            'targets': '#hero-' + be.srcHero + ' .bullet',
                            duration: 1,
                            opacity: [0, 1],
                            rotate: rad+'rad',
                            easing: 'linear'
                        }, releaseTick)
                        this.timeline.add({
                            'targets': '#hero-' + be.srcHero + ' .bullet',
                            duration: (be.data.dmgDelay/2) * this.msPerTick,
                            'left': [0, rox],
                            'top': [0, roy],
                            easing: 'linear'
                        }, releaseTick)
                        this.timeline.add({
                            'targets': '#hero-' + be.srcHero + ' .bullet',
                            duration: 1,
                            opacity: [1, 0],
                        }, endTick )
                    }
                }

                if(be.type === "D"){
                    this.timeline.add({
                        'targets': this.heroes[be.srcHero],
                        'hp': "-=" + be.amount,
                        duration: 1
                    }, be.tick * this.msPerTick)
                }

                if(be.type === "K"){
                    this.timeline.add({
                        'targets': this.heroes[be.srcHero],
                        'hp': 0,
                        'action': ACTIONS.die,
                        duration: 1
                    }, be.tick * this.msPerTick)

                    this.timeline.add({
                        'targets' : '#hero-' + be.srcHero,
                        duration: 1000,
                        opacity: [1,0]
                    }, be.tick * this.msPerTick + 3000)
                }
                this.sliderMax = this.timeline.duration
            }
        },
    },
    props:['battleId'],
    mounted() {
        console.log('mounted')
    },
    activated() {
        this.loadBattle()
        console.log('activated')
    },
    computed: {
        ...mapFields([
            'battleService',
            'app_auth'
        ]),
        cssProps() {
            return {
                '--sprite-size': (this.spriteSize) + "px",
                '--tile-size': (this.tileSize) + "px",
            }
        }

    },
}
</script>

<style scoped>
.battlefield-container{
    max-height: 100vh;
    position: relative;
    margin: var(--tile-size);
    display: inline-block;
}
.zone-row{
    display: inline-block;
    float: left;
    clear:left;
    margin: 0;
    padding: 0;
    height: var(--tile-size);
    white-space: nowrap;
}
.zone-box{
    width: var(--tile-size);
    height: var(--tile-size);;
    display: inline-block;
    line-height: var(--tile-size);
    position: relative;
}
.water{
    background: url('../assets/watera.png') -10px -10px
}
.plains{
    background: url('../assets/grassa.png') -10px -10px
}
.forest{
    background: url('../assets/foresta.png') -10px -10px
}
.hero{
    position: absolute;
    top:0;
    left:0;
    z-index: 100;
}




</style>
