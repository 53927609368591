<template>
    <div class="card mt-4">
        <div class="card-body">
            <div class="row">
                <div class="col-2">
                    <img src="../../assets/monster.png">
                </div>
                <div class="col-10">
                    <h5 class="card-title">{{ hero.heroType.name }} ({{ hero.id }})</h5>
                    <div class="d-flex flex-row flex-wrap justify-content-between text-muted">
                        <h6 class="card-subtitle">Level: {{ hero.level }}</h6>
                        <h6 class="card-subtitle">Exp: {{ hero.exp }}</h6>
                        <h6 class="card-subtitle">Attack: {{ hero.heroType.physicalAttack + hero.heroType.magicalAttack }}</h6>
                        <h6 class="card-subtitle">Defense: {{ hero.heroType.physicalDefense + hero.heroType.magicalDefense }}</h6>
                        <h6 class="card-subtitle">Atk Speed: {{ hero.heroType.attackSpeed }}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
      data() {
          return {
          }
      },
        props:['hero']
}
</script>

<style>

</style>
