<template>
    <div class="zone-box"
    v-bind:class="{
        'water'  : this.zoneCoordinate.type === 'W',
        'plains' : this.zoneCoordinate.type === 'P',
        'forest' : this.zoneCoordinate.type === 'F',
    }"
         v-on:click="selected()"
    >
        <div class="zone-item" :class="
            {
                'active-box':  (coordinatePosition === selectedPosition),
                'owned': this.ownedByCurrentUser,
                'enemy': this.ownedByEnemy
            }"
             v-if="this.zoneCoordinate.heroes !== undefined"
        >
            <span v-if="this.zoneCoordinate.heroes.length > 0"><img src="../../assets/monster.png"></span>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
      data() {
          return {
          }
      },
      methods:{
          selected(){
              this.$emit("selected", this.coordinatePosition)
          }
      },
      props:['zoneCoordinate', 'selectedPosition', 'coordinatePosition'],
      emits:['selected'],
      computed: {
          ownedByCurrentUser(){
              return this.zoneCoordinate.claimedBy !== null && this.zoneCoordinate.claimedBy.id === this.app_auth.user.id
          },
          ownedByEnemy(){
              return this.zoneCoordinate.claimedBy !== null && !this.ownedByCurrentUser
          },
          ...mapState([
              'app_auth',
          ]),
      },
}
</script>

<style scoped>
.zone-item{
    position: absolute;
    top:10px;
    left:0;
    right: 0;
    bottom: 0;
    height: 85px;
    text-align: center;
    line-height: 85px;
    z-index: 8;
    color: white;
    font-weight: bold;
}
.owned{
    border: 3px solid #00ff00;
}
.enemy{
    border: 3px solid #ff0000;
}
.zone-box{
    width: 85px;
    height: 95px;
    display: inline-block;
    line-height: 95px;
    position: relative;
    padding-top: 10px;
}
.zone-item:hover{
    outline: 3px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
    z-index: 10;
}
.active-box, .active-box.zone-item:hover{
    outline: 3px solid #ffd100;
    box-shadow: 0 0 15px #ffd100 inset;
    z-index: 10;
}
.water{
    background: url('../../assets/watera.png')
}
.plains{
    background: url('../../assets/grassa.png')
}
.forest{
    background: url('../../assets/foresta.png')
}

</style>
