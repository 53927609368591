<template>
    <div
        v-if="this.battleEnvironment"
        class="battle-environment-container bg-dark"
        ref="map"
        v-on:mousedown="dragStart"
        v-on:mousemove="dragMove"
        v-on:mouseup="dragStop"
        v-on:touchstart="dragStart"
        v-on:touchmove="dragMove"
        v-on:touchend="dragStop"
    >
        <div class="clearfix d-inline-block" style="position: relative;">
            <div v-for="y in this.battleEnvironment.height" :key="y" class="zone-row">
                <div v-for="x in this.battleEnvironment.width" :key="x" class="zone-box" style="z-index: 1;"
                     v-on:click="coordinateClicked(x,y)"
                     v-on:mousemove="coordDragged(x,y)"
                     v-bind:class="{
                        'water'  : this.zoneType(x,y) === 'W',
                        'plains' : this.zoneType(x,y) === 'P',
                        'forest' : this.zoneType(x,y) === 'F',
                     }"
                >
                    <EnvironmentTile style="width: 32px; height: 32px;" :tile="zoneCoordinate.floor[this.toIndex(x,y,this.battleEnvironment.width)]"/>
                    <EnvironmentTile style="width: 32px; height: 32px; position:absolute; top:0; left: 0;" :tile="zoneCoordinate.roads[this.toIndex(x,y,this.battleEnvironment.width)]"/>
                    <EnvironmentTile style="pointer-events:none; position:absolute; bottom:0; left: 0;" :tile="zoneCoordinate.environment[this.toIndex(x,y,this.battleEnvironment.width)]"/>
                    <div class="zone-item" v-bind:class="{
                    'active-box': this.selectedCoordinate === this.toIndex(x,y,this.battleEnvironment.width)
                    }"
                    >

                    <template v-if="this.battleEnvironment.hero[x-1][y-1]">
                        <Sprite
                            :type="this.battleEnvironment.hero[x-1][y-1].hero.heroType.name"
                            :id="this.battleEnvironment.hero[x-1][y-1].hero.id"
                            :direction="2"
                            :action="0"
                            :owned = "this.currentUser === this.battleEnvironment.hero[x-1][y-1].hero.user['@id']"
                            :enemy = "this.currentUser !== this.battleEnvironment.hero[x-1][y-1].hero.user['@id']"
                            v-on:mousedown.stop="this.heroDragStart($event, x-1,y-1)"

                        />
                    </template>
                </div>
                </div>
            </div>
            <div class="entry-point"
                 :style="entryPointPosition"
            >
            </div>
        </div>
    </div>
    <div style="position:absolute;pointer-events: none;z-index: 10"
         v-if="this.selectedHero && this.dragInfo.dragging && this.dragInfo.mode ==='hero'"
         :style="{'left': (this.dragInfo.currentX - 16) + 'px', 'top': (this.dragInfo.currentY) + 'px'}">
        <Sprite
            :type="this.selectedHero.hero.heroType.name"
            :id="this.selectedHero.hero.id"
            :direction="2"
            :action="1"
            :loop="true"
            :owned = "this.currentUser === this.selectedHero.hero.user['@id']"

        />
    </div>
</template>

<script>


import Sprite from "./Sprite";
import EnvironmentTile from "./EnvironmentTile";

export default {
    data() {
        return {
            selectedHero: null,
            dragInfo:{
                left: 0,
                top: 0,
                x: 0,
                y:0,
                currentX:0,
                currentY:0,
                which: 0,
                dragging: false,
                mode: 'map',
                heroX: 0,
                heroY: 0
            },
            tileWidth: 32
        }
    },
    components: {Sprite, EnvironmentTile},
    props: ['battleEnvironment', 'selectedCoordinate', 'currentUser', 'zoneCoordinate'],
    emits: ['coordinateClicked', 'heroMoved', 'heroSelected'],
    computed: {
        entryPointPosition(){
            let ep = this.zoneCoordinate.entryPosition;
            let p = this.toXY(ep, this.zoneCoordinate.battleEnvironment.width)
            return {
                'top' : (p.x * this.tileWidth) + 'px',
                'left' : (p.y * this.tileWidth) + 'px',
                'width' : (this.zoneCoordinate.entryWidth * this.tileWidth) + 'px',
                'height' : (this.zoneCoordinate.entryHeight * this.tileWidth) + 'px'
            }
        }
    },
    methods: {
        toXY(coordinate, width){
            return {
                x : coordinate%width,
                y : Math.floor(coordinate/width)
            };
        },
        toIndex(x,y, width){
            return (x-1) + (y-1) * width
        },
        async dragStop(evt){
            if(this.dragInfo.dragging && this.dragInfo.mode ==='hero'){
                let newX = Math.round(this.dragInfo.heroX + (evt.clientX-this.dragInfo.x) / 32)+1
                let newY = Math.round(this.dragInfo.heroY + (evt.clientY-this.dragInfo.y) / 32)+1
                let newCoordinate = this.toIndex(newX, newY, this.battleEnvironment.width)
                let oldCoordinate = this.toIndex(this.dragInfo.heroX+1, this.dragInfo.heroY+1, this.battleEnvironment.width)
                if(newX !== this.dragInfo.heroX || newY !== this.dragInfo.heroY) {
                    await this.$emit('heroMoved', oldCoordinate, newCoordinate)
                    this.coordinateClicked(newX, newY)
                }
            }
            this.dragInfo.dragging = false
        },
        async heroDragStart(evt, x, y){
            if(this.battleEnvironment.hero[x][y] === this.selectedHero){
                if(this.currentUser === this.selectedHero.hero.user['@id']) {
                    await this.dragStart(evt)
                    this.dragInfo.mode = 'hero'
                    this.dragInfo.heroX = x
                    this.dragInfo.heroY = y
                }
            }
        },
        async dragStart(evt){

            if(this.dragInfo.dragging === false) {
                this.dragInfo.mode="map"
                this.dragInfo.left = this.$refs['map'].scrollLeft
                this.dragInfo.top = this.$refs['map'].scrollTop
                if('clientX' in evt) {
                    this.dragInfo.x = evt.clientX
                    this.dragInfo.y = evt.clientY
                }
                else{
                    this.dragInfo.x = evt.touches[0].clientX
                    this.dragInfo.y = evt.touches[0].clientY
                }

                this.dragInfo.currentX = this.dragInfo.x
                this.dragInfo.currentY = this.dragInfo.y
                this.dragInfo.dragging = true
                this.which = evt.which

            }
        },
        async coordDragged(x,y){
            if(this.dragInfo.dragging) {
                if(this.dragInfo.mode==="map") {
                    let newCoordinate = this.toIndex(x, y, this.battleEnvironment.width)
                    this.$emit('coordinateClicked', newCoordinate)
                }
            }
        },
        async dragMove(evt){
            if(this.dragInfo.dragging) {
                if(evt.which === this.which) {
                    let dx, dy
                    if('clientX' in evt) {
                        dx = evt.clientX - this.dragInfo.x;
                        dy = evt.clientY - this.dragInfo.y;
                        this.dragInfo.currentX = evt.clientX
                        this.dragInfo.currentY = evt.clientY
                    }
                    else{
                        dx = evt.touches[0].clientX - this.dragInfo.x;
                        dy = evt.touches[0].clientY - this.dragInfo.y;
                        this.dragInfo.currentX = evt.touches[0].clientX
                        this.dragInfo.currentY = evt.touches[0].clientY
                    }
                    // How far the mouse has been moved

                    // Scroll the element
                    if(this.dragInfo.mode === 'map') {
                        this.$refs['map'].scrollTop = this.dragInfo.top - dy;
                        this.$refs['map'].scrollLeft = this.dragInfo.left - dx;
                    }
                }
                else{
                    this.dragStop()
                }
            }
        },
        async coordinateClicked(x,y){
            this.$emit('coordinateClicked', this.toIndex(x,y,this.battleEnvironment.width))
            if(this.battleEnvironment.hero[x-1][y-1]){
                this.selectedHero = this.battleEnvironment.hero[x-1][y-1]
            }
            else{
                this.selectedHero = null
            }
            this.$emit("heroSelected", this.selectedHero)
        },
        zoneType(x,y){
            return this.battleEnvironment.terrain[this.toIndex(x,y,this.battleEnvironment.width)]
        }
    },
}
</script>

<style scoped>
.entry-point{
    position:absolute;
    pointer-events: none;
    z-index: 100;
    background-color: rgba(0,0,255,0.2);
    border: 1px dotted white;
}
.battle-environment-container{
    overflow: hidden;
    max-height: 100vh;
    user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
img{
    user-select: none;
}
.zone-row{
    display: block;
    margin: 0;
    padding: 0;
    height: 32px;
    white-space: nowrap;
    float:left;
    clear:left;
}
.zone-box{
    width: 32px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    position: relative;
}
.zone-item{
    position: absolute;
    cursor: pointer;
    top:0px;
    left:0;
    right: 0;
    bottom: 0;
    height: 32px;
    text-align: center;
    line-height: 32px;
    z-index: 8;
}
.water{
    background: url('../../assets/watera.png') -10px -10px
}
.plains{
    background: url('../../assets/grassa.png') -10px -10px
}
.forest{
    background: url('../../assets/foresta.png') -10px -10px
}
.zone-item:hover{
    outline: 3px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
    z-index: 10;
}
.active-box, .active-box.zone-item:hover{
    outline: 3px solid #ffd100;
    box-shadow: 0 0 15px #ffd100 inset;
    z-index: 10;
}
</style>
