let tiles = {
    'clear': {
      id: 'clear',
      class: 'clear'
    },
    1:{
      id: 1,
      class: 'land-1',
    },
    2: {
      id: 2,
      class: 'road-14'
    },
    3: {
      id: 3,
      class: 'road-15'
    },
    4: {
      id: 4,
      class: 'road-16'
    },
    5: {
      id: 5,
      class: 'road-17'
    }
}
for (const key of Object.keys(tiles)) {
  tiles[key].type = 'floor'
}
export default tiles
