import {createRouter, createWebHashHistory} from "vue-router";
import Heroes from "./components/Heroes";
import Login from "./components/Login";
import Battle from "./components/Battle";
import Leaderboards from "./components/Leaderboards";
import BattleFormation from "./components/BattleFormation";
import Register from "./components/Register";
import PlayerProfile from "./components/PlayerProfile";
import Duel from "./components/Duel";
import ViewZone from "./components/ViewZone";
import ViewZoneCoordinate from "./components/ViewZoneCoordinate"
import ViewBattle from "./components/ViewBattle";
import MyProfile from "./components/MyProfile";
import store from "./store";
import HeroDetail from "./components/HeroDetail";
import RedeemHero from "./components/RedeemHero";

const routes = [
  { path: '/heroes', name: 'heroes', component: Heroes },
  { path: '/login', name: 'login', component: Login },
  { path: '/register', name: 'register', component: Register},
  { path: '/battle', name: 'battle', component: Battle },
  { path: '/duel/:playerId', name: 'duel', component: Duel, props: true },
  { path: '/battle/:battleTypeId/', name: 'battleFormation', component: BattleFormation, props: true },
  { path: '/battle/view/:battleId/', name: 'viewBattle', component: ViewBattle, props: true },
  { path: '/profile/:playerId', name: 'profile', component: PlayerProfile, props: true},
  { path: '/heroDetails/:heroId', name: 'heroDetails', component: HeroDetail, props: true},
  { path: '/myProfile', name: 'myProfile', component: MyProfile, meta: { requiresAuth: true }},
  { path: '/myProfile/redeemHero', name: 'redeemHero', component: RedeemHero, meta: { requiresAuth: true }},
  { path: '/zone/', name: 'zone', component: ViewZone},
  { path: '/zone/coordinate/:zoneCoordinateId', name: 'viewZoneCoordinate', component: ViewZoneCoordinate, props: true},
  { path: '/', name: 'home', component: Leaderboards },
]

const router = createRouter ({
  history: createWebHashHistory(),
  linkActiveClass: 'active',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.getField('app_auth.is_authenticated')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }
  next()
})

export default router
