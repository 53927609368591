<template>
    <div v-if="this.visible" class="game-modal" >
        <div class="game-modal-title">{{ title }} <i v-if="this.closeButton == 'true'" class="bi bi-x-circle game-modal-close" v-on:click="this.close()"></i></div>
        <div class="game-modal-body">
            <slot></slot>
        </div>
        <div class="mt-1 d-flex flex-row justify-content-around w-100">
            <template v-if="this.centerButton">
                <router-link v-if="this.centerButton.to" :to="this.centerButton.to" class="game-modal-button">{{this.centerButton.text}}</router-link>
                <button v-else v-on:click="centerButtonClicked()" class="game-modal-button" type="submit">{{this.centerButton.text}}</button>
            </template>
            <template  v-if="this.rightButton">
                <router-link v-if="this.rightButton.to" :to="this.rightButton.to" class="game-modal-button">{{this.rightButton.text}}</router-link>
                <button v-else v-on:click="rightButtonClicked()" class="game-modal-button" type="button">{{this.rightButton.text}}</button>
            </template>
        </div>
    </div>
</template>

<script>
  export default {
      data() {
          return {
              visible : true
          }
      },
      methods:{
          close(){
              this.$emit('closed')
          },
          centerButtonClicked(){
              this.$emit('centerButtonClicked')
          },
          rightButtonClicked(){
              this.$emit('rightButtonClicked')
          }
      },
      emits:['closed', 'centerButtonClicked', 'rightButtonClicked'],
      props:['title', 'centerButton','rightButton', 'closeButton']
}
</script>

<style scoped>
.game-modal{
    box-shadow: 0 0 5px var(--color-primary-1) inset;
    border: 2px solid var(--color-primary-1);
    background: var(--color-primary-2);
    font-family: 'Teko', sans-serif;
    color: var(--color-primary-1);
    font-size: 22px;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.game-modal-title{
    text-align: center;
    box-shadow: 0 0 2px var(--color-primary-2) inset;
    border: 2px solid var(--color-primary-1);
    background: var(--color-primary-1);
    font-size: 26px;
    font-weight: bold;
    color: var(--color-primary-4);
    border-radius: 5px;
    position: relative;

}
.game-modal-body{
    padding: 15px;
    flex-grow: 1;
    box-shadow: 0 0 2px var(--color-primary-2) inset;
    border: 2px solid var(--color-primary-1);
    background: var(--color-primary-4);
    border-radius: 5px;
    position: relative;
}
.game-modal-close:hover{
    cursor: pointer;
    color: var(--color-primary-3);
    text-shadow: 0 0 5px var(--color-primary-4);
}
.game-modal-close{
    position: absolute;
    right: 10px;
    top:3px;
}

a.game-modal-button:hover, button.game-modal-button:hover{
    background: var(--color-primary-5);
    cursor: pointer;
}
a.game-modal-button:active, button.game-modal-button:active{
    background: var(--color-primary-1);
}

a.game-modal-button, button.game-modal-button{
    box-shadow: 0 0 2px var(--color-primary-2) inset;
    outline: 1px solid var(--color-primary-2);
    border: 2px solid var(--color-primary-1);
    background: var(--color-primary-1);
    color: var(--color-primary-4);
    font-size: 22px;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding: 5px 15px;
    margin: 3px;
}
</style>

<style>
.game-modal-body a{
    color: var(--color-primary-5);
}
</style>
