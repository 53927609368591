<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="p-4">
                    <h1 class="">Player Profile</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-wrap p-3 bd-highlight">
                    {{ user.username }}
                </div>
                <hr/>
                <div>
                    Gold: {{ user.gold }}
                </div>
            </div>
        </div>
        <div class="row">
            <router-link :to="{name: 'duel', params: {'playerId': this.playerId }}">Duel</router-link>
        </div>
        <div class="row">
            <div v-for="hero in user.heroes" :key="hero" class="col-md-3">
                <MiniHeroShowcase :hero="hero"></MiniHeroShowcase>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import MiniHeroShowcase from "./widgets/MiniHeroShowcase";

export default {
    data() {
        return {
            user: {
                username: '',
                hero: [],
                battleFormations: []
            },
            duelFormation: {

            }
        }
    },
    components: {
        MiniHeroShowcase
    },
    props:['playerId'],
    mounted(){
      this.$store.getters.getField("userService").get(this.playerId).then(r => {
          this.user = r

      })
    },
    computed: {
        ...mapState([

         ]),
    },
}
</script>

<style>

</style>
