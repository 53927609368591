<template>
    <Modal
        title="My Profile"
        :center-button="{'text': 'Logout' }"
        :right-button="{'text': 'Redeem Hero', 'to': {name: 'redeemHero', params: {}}}"
        v-on:centerButtonClicked="this.logout()"
        class="centered-form"
    >
        <p>Logged in as: {{ app_auth.username }}</p>
    </Modal>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import Modal from "./widgets/Modal"
export default {
    data() {
        return {
            username: '',
            password: ''
        }
    },
    computed: {
        ...mapFields([
            'app_auth',
        ]),
    },
    activated() {
    },
    components:{
        Modal
    },
    methods:{
        logout(){
            this.$store.dispatch('logout')
            this.username = ""
            this.password = ""
        }
    }
}
</script>
