import axios from 'axios'
 import { ref } from 'vue'

export default(resourceName, normalizer) => {
  let loading = ref(0)

  let iriCache = {}

  /** Returns the IRI given an IRI or an ID **/
  function getIri(id){
    let ids = id + "";
    if(ids.includes("/")){
      return ids;
    }
    else{
      return 'api/' + resourceName + "/" + id;
    }
  }

  /** Returns the id given an IRI or an ID **/
  function getId(identifier){
    let result = identifier + ""
    if(result.includes("/")){
      let parts = result.split("/");
      result = parts[parts.length - 1];
    }
    return result
  }

  async function getById(id){
    loading.value++
    id = getId(id)
    let result = null
    try {
      result = await axios.get(resourceName + '/' + id)
      iriCache[result.data['@id']] = result.data
      return result.data
    }
    catch(e){
      console.log(e)
    }
    finally{
      loading.value--
    }

    return result
  }

  return {
    getIri: getIri,
    getId: function(f){
      return getId(f)
    },
    get: async function(id) {
      return await getById(id)
    },
    getFromCache(iri){
      if(iri in iriCache){
        return iriCache[iri]
      }
      return null
    },
    isLoading : function(){
      return loading.value > 0
    },
    clearCache(){
      iriCache = {}
    },
    allByIri: function(){
      return iriCache
    },
    async search(filters, options = {subResource: null, maxPages : 1, simple: true}){
      loading.value++
      try {
        let params = {
          page: 1,
          ...filters
        }
        let defaults = {
          maxPages: 1,
          subResource: null
        }
        options = {
          ...defaults,
          ...options
        }

        let finalResourceName = resourceName
        if(options.subResource !== null){
          finalResourceName = resourceName + options.subResource
        }

        let result = await axios.get(finalResourceName, {params: params});
        if (result.data['hydra:view']) {
          let next = result.data['hydra:view']['hydra:next']
          while (next && params.page < options.maxPages) {
            params["page"] += 1
            let new_result = await axios.get(finalResourceName, {params: params});
            next = "";
            if (new_result.data['hydra:member']) {
              for (let i = 0; i < new_result.data['hydra:member'].length; i++) {
                let r = new_result.data['hydra:member'][i]
                iriCache[r['@id']] = r
                result.data['hydra:member'].push(r)
              }
              next = new_result.data['hydra:view']['hydra:next']
            }
          }
        }
        if (options.simple) {
          return result.data['hydra:member']
        }
        return result
      }
      catch(e){
        console.log(e)
      }
      finally {
        loading.value--
      }
    },
    async postItemAction(id, action, payload, config){
      loading.value++
      try {
        if(normalizer !== null && typeof normalizer === 'function'){
          payload = normalizer(payload)
        }
        return await axios.post(resourceName + '/' + id + '/' + action, payload, config);
      }
      catch(e){
        console.log(e)
      }
      finally {
        loading.value--
      }
    },
    async getItemAction(id, action){
      loading.value++
      try {
        return await axios.get(resourceName + '/' + id + '/' + action);
      }
      catch(e){
        console.log(e)
      }
      finally {
        loading.value--
      }
    },
    async create(payload, config) {
      loading.value++
      if(normalizer !== null){
        payload = normalizer(payload)
      }
      try {
        return await axios.post(resourceName, payload, config);
      }
      catch(e){
        console.log(e)
      }
      finally {
        loading.value--
      }
    },
    async del(id) {
      loading.value++
      try {
        return await  axios.delete(resourceName + '/' + id);
      }
      catch(e){
        console.log(e)
      }
      finally {
        loading.value--
      }
    },
    async update(payload) {
      loading.value++
      try {
        if(normalizer !== null && normalizer !== undefined){
          payload = normalizer(payload)
        }
        return await axios.patch(resourceName + '/' + payload['id'], payload, {
          headers: {'content-type' :  'application/merge-patch+json'}
        });
      }
      catch(e){
        console.log(e)
      }
      finally {
        loading.value--
      }

    }
  }
}
