import {createStore} from 'vuex'
import {getField, updateField} from 'vuex-map-fields'
import axios from "axios"
import jwtAuth from "./security/jwt-auth"
import leaderboardService from "./services/leaderboard-service"
import battleFormationService from "./services/battle-formation-service"
import heroService from './services/hero-service'
import battleTypeService from './services/battle-type-service'
import userService from './services/user-service'
import battleService from './services/battle-service'
import zoneService from './services/zone-service'
import zoneCoordinateService from './services/zone-coordinate-service'


const store = createStore({
  state: {
    app_auth: {
      is_authenticated: false,
      active_token: null,
      username : null,
      iat: null,
      exp: null,
      roles: [],
      user: {}
    },
    leaderboardService: leaderboardService,
    battleFormationService: battleFormationService,
    battleTypeService: battleTypeService,
    heroService: heroService,
    userService: userService,
    battleService: battleService,
    zoneService: zoneService,
    zoneCoordinateService: zoneCoordinateService,
    baseCollection: null,
    flash_message: null,
    is_logging_in: 0,
    is_loading_heroes: 0,
    is_loading_battle_types: 0,
    is_loading_battle_formations: 0,
    is_loading_battles: 0,
    battles: [],
    leaderboards: [],
    battle_types: [],
    battle_formations: [],
    heroes_owned_by_active_user : [],
    validationErrors: {},
    zone:{
      zoneCoordinates: [],
      byIndex:{},
      width: 0,
      height: 0
    },
    zoneView:{
      left: 0,
      top: 0
    }
  },
  getters: {
    getField
  },
  mutations: {
    setJwtToken(state, newToken) {
      let resetToken = true
      try {
        let token = jwtAuth.parse_jwt_token(newToken)
        if(!jwtAuth.is_expired(token.exp)) {
          state.app_auth.active_token = newToken
          state.app_auth.is_authenticated = true
          state.app_auth.username = token.username
          state.app_auth.iat = token.iat
          state.app_auth.exp = token.exp
          state.app_auth.roles = token.roles
          state.app_auth.user = {}
          resetToken = false
        }
      }
      catch(e){
        resetToken = true
      }
      if(resetToken){
        state.app_auth.active_token = null
        state.app_auth.is_authenticated = false
        state.app_auth.username = null
        state.app_auth.iat = null
        state.app_auth.exp = null
        state.app_auth.roles = []
        state.app_auth.user = {}
      }
      jwtAuth.save_token(newToken)
    },
    setFlashMessage(state, message){
      state.flash_message = message
    },
    notifyLoggingIn(state, delta){
      state.is_logging_in += delta
    },
    notifyLoadingHeroes(state, delta){
      state.is_loading_heroes += delta
    },
    notifyLoadingBattleTypes(state, delta){
      state.is_loading_battle_types += delta
    },
    notifyLoadingBattleFormations(state, delta){
      state.is_loading_battle_formations += delta
    },
    notifyLoadingBattles(state, delta){
      state.is_loading_battles += delta
    },
    updateHeroes(state, heroes){
      state.heroes = heroes
    },
    updateBattleTypes(state, battleTypes){
      state.battle_types = battleTypes
    },
    updateBattleFormations(state, battleFormations){
      state.battle_formations = battleFormations
    },
    updateBattles(state, battles){
      state.battles = battles
    },
    updateField
  },
  actions: {
    logout({commit}){
      commit('setJwtToken', null)
    },
    async register({commit, dispatch}, {username, password}) {
      commit('notifyLoggingIn', 1)
      commit('setFlashMessage', null)

      axios
        .post(process.env.VUE_APP_API_ENDPOINT +"api/v1/users", {
          'username': username,
          'newPassword': password
        })
        .then(function () {
          dispatch('login', {username, password})
        })
        .catch(function (error) {
          if(error.response === undefined){
            commit('setFlashMessage', 'Unknown Error')
          }
          else {
            commit('setFlashMessage', error.response.data['hydra:description'])
          }
        })
        .finally(()=>{
          commit('notifyLoggingIn', -1)
        })
    },
    async redeemHero({commit}, redemptionCode){
      commit('notifyLoadingHeroes', 1)
      await axios.post("hero/redeem", {'code' : redemptionCode})
        .catch(function(){
          commit('setFlashMessage', "Error redeeming code.")
        })
        .finally(function(){
          commit('notifyLoadingHeroes', -1)
        })

    },
    async login({commit, dispatch}, {username, password}) {
      commit('notifyLoggingIn', 1)
      commit('setFlashMessage', null)
      try {
        let response = await axios
          .post(process.env.VUE_APP_API_ENDPOINT +"authentication_token", {
            'username': username,
            'password': password
          })
        commit('setJwtToken', response.data.token)
        jwtAuth.save_token(response.data.token)
        await dispatch("getCurrentUser")
      }
      catch(e){
        commit('setFlashMessage', e.response.data.message)
      }
      finally{
        commit('notifyLoggingIn', -1)
      }
    },
    async getCurrentUser({commit, getters}){
      let username = getters.getField("app_auth.username")
      try {
        if(username !== null) {
          let user = await userService.search({'username': username})
          if(user.length === 1) {
            commit('updateField', {path: "app_auth.user", value: user[0]})
          }
        }
      }
      catch(e){
        console.log(e)
      }
    },
    async loadHeroesByUsername({commit}, {username: username}) {

      commit('notifyLoadingHeroes', 1)
      commit('setFlashMessage', null)
      axios
        .get("/heroes", {'params': {'user.username' : username}})
        .then(function (response) {
          commit('updateHeroes', response.data["hydra:member"])
        })
        .catch(function (error) {
          if(error.response) {
            commit('setFlashMessage', error.response.data.message)
          }
          else{
            throw error
          }
        })
        .finally(()=>{
          commit('notifyLoadingHeroes', -1)
        })
    },
    async loadBattleTypes({commit}) {
      commit('notifyLoadingBattleTypes', 1)
      commit('setFlashMessage', null)
      axios
        .get("/battle_types")
        .then(function (response) {
          commit('updateBattleTypes', response.data["hydra:member"])
        })
        .catch(function (error) {
          commit('setFlashMessage', error.response.data.message)
        })
        .finally(()=>{
          commit('notifyLoadingBattleTypes', -1)
        })
    },
    async loadBattleFormations({commit}, {battleType: battleType, username: username}) {
      commit('notifyLoadingBattleFormations', 1)
      commit('updateBattleFormations', [])
      commit('setFlashMessage', null)
      axios
        .get("/battle_formations", {'params': {'battleType' : battleType, 'user.username': username}})
        .then(function (response) {
          commit('updateBattleFormations', response.data["hydra:member"])
        })
        .catch(function (error) {
          commit('setFlashMessage', error.response.data.message)
        })
        .finally(()=>{
          commit('notifyLoadingBattleFormations', -1)
        })
    },
    async loadLeaderboards({commit}){
      let results = await leaderboardService.search({},1)
      commit('updateField', {path: "leaderboards", value: results.data['hydra:member']})
    },
    async loadBattles({commit}) {
      commit('notifyLoadingBattles', 1)
      commit('setFlashMessage', null)
      axios
        .get("/battles")
        .then(function (response) {
          commit('updateBattles', response.data["hydra:member"])
        })
        .catch(function (error) {
          commit('setFlashMessage', error.response.data.message)
        })
        .finally(()=>{
          commit('notifyLoadingBattles', -1)
        })
    },
    async saveBattleFormation({commit}, battleFormation) {
      commit('notifyLoadingBattleFormations', 1)
      commit('setFlashMessage', null)

      let postData = {
        "battleType" : battleFormation.battleType,
        "name": battleFormation.name,
        "initialPositions" : []
      }
      Object.keys(battleFormation.initialPositions).forEach(key => {
        postData.initialPositions.push({
          "hero" : battleFormation.initialPositions[key],
          "position" : parseInt(key)
        })
      })
      axios
        .post("/battle_formations", postData)
        .then(function (response) {
          commit('updateBattleFormations', response.data["hydra:member"])
        })
        .catch(function (error) {
          commit('setFlashMessage', error.response.data.message)
        })
        .finally(()=>{
          commit('notifyLoadingBattleFormations', -1)
        })
    },
    async deleteBattleFormation({commit}, battleFormationId) {
      commit('notifyLoadingBattleFormations', 1)
      commit('setFlashMessage', null)

      axios
        .delete("/battle_formations/"+battleFormationId)
        .then()
        .catch(function (error) {
          commit('setFlashMessage', error.response.data.message)
        })
        .finally(()=>{
          commit('notifyLoadingBattleFormations', -1)
        })
    },
  }
})

export default store

