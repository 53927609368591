<template>

    <div id="game-container">
        <MenuBar
            id="logo"
        >
            <button
                class="light d-block d-md-none"
                style="flex: none;" type="button"
                v-on:click="this.collapse = !this.collapse"
            >
                <span class="bi bi-list"></span>
            </button>
            <h1 class="text-center mt-2" style="flex: 1;">NFT Wargame</h1>
        </MenuBar>
        <MenuBar
            id="side-menu"
            ref="side-menu"
            vertical="true"
            :class="{'collapse' : this.collapse}"
            >
            <router-link v-if="app_auth.is_authenticated !== true" class="light" to="/login">Login</router-link>
            <router-link v-if="app_auth.is_authenticated === true" class="light" to="/myprofile">Profile</router-link>
            <router-link v-if="app_auth.is_authenticated === true" class="light" to="/heroes">Heroes</router-link>
            <router-link class="light" to="/">Leaderboards</router-link>
            <router-link class="light" to="/zone" tag="button">Map</router-link>
        </MenuBar>
        <div id="main-view">
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" :key="$route.fullPath"></component>
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import MenuBar from "./widgets/MenuBar"
export default {
    data() {
        return {
            user: null,
            collapse: true
        }
    },
    components: {
        MenuBar
    },
    computed:{
        ...mapFields([
            'app_auth',
            'userService'
        ])
    },
    methods:{
        async collect(){
            this.user = await this.userService.getItemAction(this.app_auth.user.id, 'collect')
        }
    },
    watch: {
        '$route': function() {
            this.collapse = true
        }
    }
}
</script>

<style>

#game-container{
    display: grid;
    height: 100%;
    grid-template-columns: [first] 250px [second] 1fr [third] 250px [end];
    grid-template-rows: [first] 60px [second] 1fr [end];
    grid-template-areas: "logo content content"
                         "menu content content"
}
#top-menu{
    grid-column: second/end;
    grid-row: first/second
}
#logo{
    grid-area: logo;
    background-color: var(--color-primary-2);
    color: var(--color-primary-5);
}
#side-menu{
    grid-area: menu
}

#main-view{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: [first] 1fr [second] 1fr [third] 1fr [end];
    grid-template-rows: [header] 60px [first] 1fr [second] 1fr [third] 1fr [end];
    grid-template-areas: "fullcontent fullcontent fullcontent"
                         "fullcontent fullcontent fullcontent"
                         "fullcontent fullcontent fullcontent"
                         "fullcontent fullcontent fullcontent";

    overflow: auto;
    justify-content: center;
    grid-area: content
}
.centered-form{
    grid-column: second/third;
    grid-row: first/second;
}

@media(min-width: 769px){
    #side-menu{
        display: flex!important;
    }
}
@media(max-width: 768px){
    #game-container{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content min-content 1fr;
        grid-template-areas: "logo logo"
                             "menu menu"
                             "content content"
    }

    .centered-form{
        grid-column: first/end;
        grid-row: first/second;
        margin: 15px;
    }
}

.game-button.active{
    box-shadow: 0 0 2px var(--color-primary-2) inset!important;
    border: 2px solid var(--color-primary-2)!important;;
    outline: 1px solid var(--color-primary-1)!important;;
    background: var(--color-primary-1)!important;;
    color: var(--color-primary-4)!important;;
}

.game-button{
    box-shadow: 0 0 2px var(--color-primary-5) inset;
    border: 2px solid var(--color-primary-2);
    outline: 1px solid var(--color-primary-1);
    background: var(--color-primary-3);
    color: var(--color-primary-1);
}

.game-button.dark{
    box-shadow: 0 0 2px var(--color-primary-2) inset;
    outline: 1px solid var(--color-primary-2);
    border: 2px solid var(--color-primary-1);
    background: var(--color-primary-1);
    color: var(--color-primary-4);
}

.game-button.dark:hover{
    background: var(--color-primary-5);
    cursor: pointer;
    color: var(--color-primary-4);
}
.game-button.dark:active{
    background: var(--color-primary-1);
}

.game-button:hover{
    background: var(--color-primary-4);
    cursor: pointer;
}
.game-button:active{
    background: var(--color-primary-3);
}
.game-tab-title{
    box-shadow: 0 0 2px var(--color-primary-5) inset;
    border: 2px solid var(--color-primary-2);
    outline: 1px solid var(--color-primary-1);
    background: #e6e6e6;
    color: var(--color-primary-1);
}
.game-tab-container{
    box-shadow: 0 0 2px var(--color-primary-5) inset;
    border: 2px solid var(--color-primary-2);
    outline: 1px solid var(--color-primary-1);
    background: #e6e6e6;
    color: var(--color-primary-1);
}



</style>
