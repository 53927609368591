<template>
    <div
        class="battleground-tile"
        :class = "cssProps"
        :style = "styleProps"
    >
    </div>
</template>

<script>
import allTiles from "../../enums/allTiles";

export default {
    data() {
        return {

        }
    },
    created(){

    },
    props:[
        'tile'
    ],
    computed: {
        cssProps() {
            let result = []
            if(this.tile){
                result.push(allTiles[this.tile].class)
            }
            return result;
        },
        styleProps(){
            return {
                '--tile-width': '32px'
            }
        }
    }
}
</script>

<style scoped>
.clear{
    background: #fff;
    outline: 1px solid black;
}
.land-1 {
    background: url('../../assets/floor/land_1.png')
}
.road-14 {
    background: url('../../assets/floor/road_14.png')
}
.road-15 {
    background: url('../../assets/floor/road_15.png')
}
.road-16 {
    background: url('../../assets/floor/road_16.png')
}
.road-17 {
    background: url('../../assets/floor/road_17.png')
}

.road-1 {
    background: url('../../assets/roads/road_1.png')
}
.road-2 {
    background: url('../../assets/roads/road_2.png')
}
.road-3 {
    background: url('../../assets/roads/road_3.png')
}
.road-4 {
    background: url('../../assets/roads/road_4.png')
}

.road-5 {
    background: url('../../assets/roads/road_5.png')
}
.road-6 {
    background: url('../../assets/roads/road_6.png')
}
.road-7 {
    background: url('../../assets/roads/road_7.png')
}
.road-8 {
    background: url('../../assets/roads/road_8.png')
}
.road-9 {
    background: url('../../assets/roads/road_9.png')
}
.road-10 {
    background: url('../../assets/roads/road_10.png')
}
.road-11 {
    background: url('../../assets/roads/road_11.png')
}
.road-12 {
     background: url('../../assets/roads/road_12.png')
 }
.road-13 {
    background: url('../../assets/roads/road_13.png')
}

.battleground-tile.building-1{
    background-image: url('../../assets/buildings/building_1.png');
    width: calc(var(--tile-width) * 3);
    height: calc(var(--tile-width) * 7);
    margin-left: calc(var(--tile-width) * -2);
}
.battleground-tile.tree-1{
    background-image: url('../../assets/environment/tree_1.png');
    width: 48px;
    height: 96px;
    margin-left: -45%;
    background-position-y: 50%;
}

.battleground-tile.stone-1{
    background-image: url('../../assets/environment/stones_1.png');
    width: var(--tile-width);
    height: var(--tile-width);
}

.battleground-tile {
    width: 64px;
    height: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 100%;
}

.selectable:hover{
    outline: 3px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.25) inset;
    z-index: 10;
    cursor: pointer;
}
.selected, .selectable.selected:hover{
    outline: 3px solid #ffd100;
    box-shadow: 0 0 15px #ffd100 inset;
    z-index: 10;
}
</style>
