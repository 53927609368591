import Cookies from 'js-cookie'

const jwtAuth = {
  parse_jwt_token: function(jwtToken)  {
    let base64Url = jwtToken.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
    return JSON.parse(jsonPayload)
  },
  save_token(jwtToken){
    Cookies.set('token',jwtToken)
  },
  load_token() {
    return Cookies.get('token')
  },
  is_expired(exp){
    return (exp < Date.now() / 1000)
  }
}
export default jwtAuth
