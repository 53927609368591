let tiles = {
  'clear': {
    id: 'clear',
    class: 'clear'
  },
  2001:{
    id: 2001,
    class: 'building-1',
  },
  2002:{
    id: 2002,
    class: 'tree-1'
  },
  2003:{
    id: 2003,
    class: 'stone-1',
  },
}
for (const key of Object.keys(tiles)) {
  tiles[key].type = 'environment'
}
export default tiles
