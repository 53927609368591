<template>
    <div
        class="hero sprite"
        :class="{
            'face-up' : this.direction === 0,
            'face-right' : this.direction === 1,
            'face-down' : this.direction === 2,
            'face-left' : this.direction === 3,
            'idle' : this.action === ACTIONS.idle,
            'walk' : this.action === ACTIONS.walk,
            'attack' : this.action === ACTIONS.attack,
            'shoot' : this.action === ACTIONS.shoot,
            'die' : this.action === ACTIONS.die,
            'basic-warrior' : this.type === 'Fighter',
            'armored-warrior' : this.type === 'Tank',
            'basic-archer' : this.type === 'Archer',
            'team-red' : this.team === 'Attacker',
            'team-blue' : this.team === 'Defender',
            'loop' : this.loop,
            'owned' : this.owned,
            'friendly' : this.friendly,
            'enemy' : this.enemy
        }"
        :id="'hero-' + this.id"
        :style = "cssProps"
    >
        <span class="show-hover" v-if="this.label">{{ label }}</span>
        <div v-if="this.hp > 0" class="hp-wrap">
            <div class="hp-inner" v-bind:style="{width: (100 * this.hp / this.initialHp)+'%'}"></div>
        </div>
        <div class="bullet"></div>
    </div>

</template>

<script>
import ACTIONS from '../../enums/actions'

export default {
    data() {
        return {

        }
    },
    created(){
        this.ACTIONS = ACTIONS
    },
    props:[
        'type','direction','action','team','id','hp','initialHp','label', 'loop','attackLandSpeed', 'moveSpeed','owned', 'enemy', 'friendly', 'depth', 'x', 'y'
    ],
    computed: {
        cssProps() {
            return {
                'animation-duration': this.moveSpeed + "ms",
                '--attack-speed': (this.attackLandSpeed) + "ms",
                'z-index': (this.depth),
                'left': (this.x * 64) + 'px',
                'top': (this.y * 64) + 'px'
            }
        }
    }
}
</script>

<style scoped>
.bullet{
    height: 64px;
    width: 64px;
    position: absolute;
    left:0;
    top:0;
    z-index: 100;
    background-color: brown;
    opacity: 0;
    pointer-events: none;
}
.hero{
    position: absolute;
    top:0;
    left:0;
}
.sprite {
    width: 64px;
    height: 64px;
    margin-left: -16px;
    margin-top: -48px;

    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-iteration-count: 1;
    transform-style: preserve-3d;
}
.show-hover{
    display: none;
}
.sprite:hover .show-hover{
    display:block;
}
.sprite:before{
    width: 30px;
    height: 20px;
    margin-left: -15px;
    left: 50%;
    top: 80%;

    position: absolute;
    display: inline-block;
    border-radius: 38px;
    content: "";
    transform: translateZ(-1px);
    pointer-events: none;
}
.sprite.owned:before{
    border: 4px solid rgba(0,255,0,0.35)!important;
    background-color: rgba(0,255,0,0.35)!important;
}

/*.sprite.team-red{*/
/*    filter: drop-shadow(0px 0px 2px rgba(255,0,0,1));*/
/*}*/
/*.sprite.owned{*/
/*    filter: drop-shadow(0px 0px 2px rgba(0,255,0,1));*/
/*}*/
/*.sprite.team-blue{*/
/*    filter: drop-shadow(0px 0px 2px rgba(0,0,255,1));*/
/*}*/

.sprite.team-red:before, .sprite.enemy:before{
    border: 4px solid rgba(255,0,0,0.35);
    background-color: rgba(255,0,0,0.35);
}
.sprite.team-blue:before, .sprite.friendly:before{
    border: 4px solid rgba(0,0,255,0.35);
    background-color: rgba(0,0,255,0.35);
}
.team-red .hp-wrap, .enemy .hp-wrap{
    border:1px solid rgba(255,0,0,0.35);
}
.team-blue .hp-wrap, .friendly .hp-wrap{
    border: 1px solid rgba(0,0,255,0.35);
}

.hp-wrap{
    background: black;
    height: 7px;
    position: absolute;
    width: 50%;
    top: 0;
    left: 25%;
}
.hp-inner{
    background-color: green;
    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
}

.girl-warrior{
    background-repeat: no-repeat!important;
    background-size: cover!important;
    width: 128px;
    height: 128px;
    transition: transform 0.5s;
}
.girl-warrior.walk, .girl-warrior.idle{
    background:
        url('../../assets/sprites/walk/BODY.png') -0px -0px;
        /*url('../../assets/sprites/walk/WEAPON_knife.png') -0px -0px*/
}
.girl-warrior.attack{
    background:
        url('../../assets/sprites/attack/BODY.png') -0px -0px;
        /*url('../../assets/sprites/attack/WEAPON_knife.png') -0px -0px*/
}
.girl-warrior.shoot{
    background:
        url('../../assets/sprites/attack/BODY.png') -0px -0px,
        url('../../assets/sprites/attack/WEAPON_knife.png') -0px -0px
}
.girl-warrior.die{
    background:
        url('../../assets/sprites/die/BODY.png') -0px -0px;
}
.girl-warrior.face-up {
    transform: rotate3d(-1, 3, 0, 50deg);
}
.girl-warrior.face-down {
    transform: rotate3d(-1, -3, 0, 50deg);
}
.girl-warrior.face-left{
    transform: scale(-1,1);
}
.girl-warrior.face-right{

}

.girl-warrior.face-left.die, .girl-warrior.face-up.die, .girl-warrior.face-right.die, .girl-warrior.face-down.die{
    animation-name: direction-right-idle-v2;
    animation-timing-function: steps(23);
    animation-iteration-count: 1;
}

.girl-warrior.face-left.idle, .girl-warrior.face-up.idle{
    animation-name: direction-left-idle-v2;
    animation-timing-function: steps(23);
}
.girl-warrior.face-right.idle, .girl-warrior.face-down.idle{
    animation-name: direction-right-idle-v2;
    animation-timing-function: steps(23);
}

.girl-warrior.face-left.walk, .girl-warrior.face-up.walk{
    animation-name: direction-left-walk-v2;
    animation-timing-function: steps(23);
}

.girl-warrior.face-right.walk, .girl-warrior.face-down.walk{
    animation-name: direction-right-walk-v2;
    animation-timing-function: steps(23);
}

.girl-warrior.face-left.attack, .girl-warrior.face-up.attack{
    animation-name: direction-left-attack-v2;
    animation-timing-function: steps(23);
    animation-duration: var(--attack-speed)!important;
}

.girl-warrior.face-right.attack, .girl-warrior.face-down.attack{
    animation-name: direction-right-attack-v2;
    animation-timing-function: steps(23);
    animation-duration: var(--attack-speed)!important;
}


@keyframes direction-right-idle-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}

@keyframes direction-left-idle-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}

@keyframes direction-right-walk-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}

@keyframes direction-left-walk-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}

@keyframes direction-right-attack-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}

@keyframes direction-left-attack-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}

@keyframes direction-right-shoot-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}
@keyframes direction-left-shoot-v2 {
    from { background-position: 0 0; }
    to { background-position: 100% 0; }
}


.basic-archer.walk, .basic-archer.idle{
    background:
        url('../../assets/spritesheets/walkcycle/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/TORSO_leather_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/FEET_shoes_brown.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/BODY_male.png') -0px -0px;
}
.basic-archer.shoot{
    background:
        url('../../assets/spritesheets/bow/WEAPON_arrow.png') -0px -0px,
        url('../../assets/spritesheets/bow/WEAPON_bow.png') -0px -0px,
        url('../../assets/spritesheets/bow/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/bow/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/bow/TORSO_leather_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/bow/FEET_shoes_brown.png') -0px -0px,
        url('../../assets/spritesheets/bow/BODY_animation.png') -0px -0px;
}
.basic-archer.attack{
    background:
        url('../../assets/spritesheets/slash/WEAPON_dagger.png') -0px -0px,
        url('../../assets/spritesheets/slash/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/slash/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/slash/TORSO_leather_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/slash/FEET_shoes_brown.png') -0px -0px,
        url('../../assets/spritesheets/slash/BODY_human.png') -0px -0px;
}
.basic-archer .bullet{
    background: url('../../assets/spritesheets/bow/WEAPON_arrow.png') -775px -200px;
    pointer-events: none;
}
.basic-archer.die{
    background:
        url('../../assets/spritesheets/hurt/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/hurt/HEAD_leather_armor_hat.png') -0px -0px,
        url('../../assets/spritesheets/hurt/TORSO_leather_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/hurt/FEET_shoes_brown.png') -0px -0px,
        url('../../assets/spritesheets/hurt/BODY_male.png') -0px -0px;
}


.basic-warrior.walk, .basic-warrior.idle{
    background:
        url('../../assets/spritesheets/walkcycle/HEAD_chain_armor_hood.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/TORSO_chain_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/BODY_male.png') -0px -0px;
}
.basic-warrior.attack{
    background:
        url('../../assets/spritesheets/slash/WEAPON_dagger.png') -0px -0px,
        url('../../assets/spritesheets/slash/HEAD_chain_armor_hood.png') -0px -0px,
        url('../../assets/spritesheets/slash/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/slash/TORSO_chain_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/slash/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/slash/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/slash/BODY_human.png') -0px -0px;
}
.basic-warrior.shoot{
    background:
        url('../../assets/spritesheets/bow/WEAPON_arrow.png') -0px -0px,
        url('../../assets/spritesheets/bow/WEAPON_bow.png') -0px -0px,
        url('../../assets/spritesheets/bow/HEAD_chain_armor_hood.png') -0px -0px,
        url('../../assets/spritesheets/bow/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/bow/TORSO_chain_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/bow/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/bow/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/bow/BODY_animation.png') -0px -0px;
}
.basic-warrior.die{
    background:
        url('../../assets/spritesheets/hurt/HEAD_chain_armor_hood.png') -0px -0px,
        url('../../assets/spritesheets/hurt/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/hurt/TORSO_chain_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/hurt/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/hurt/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/hurt/BODY_male.png') -0px -0px;
}


.armored-warrior.walk, .armored-warrior.idle{
    background:
        url('../../assets/spritesheets/walkcycle/HEAD_plate_armor_helmet.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/TORSO_plate_armor_arms_shoulders.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/TORSO_plate_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/walkcycle/BODY_male.png') -0px -0px;
}
.armored-warrior.attack{
    background:
        url('../../assets/spritesheets/slash/WEAPON_dagger.png') -0px -0px,
        url('../../assets/spritesheets/slash/HEAD_plate_armor_helmet.png') -0px -0px,
        url('../../assets/spritesheets/slash/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/slash/TORSO_plate_armor_arms_shoulders.png') -0px -0px,
        url('../../assets/spritesheets/slash/TORSO_plate_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/slash/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/slash/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/slash/BODY_human.png') -0px -0px;
}
.armored-warrior.shoot{
    background:
        url('../../assets/spritesheets/bow/WEAPON_arrow.png') -0px -0px,
        url('../../assets/spritesheets/bow/WEAPON_bow.png') -0px -0px,
        url('../../assets/spritesheets/bow/HEAD_plate_armor_helmet.png') -0px -0px,
        url('../../assets/spritesheets/bow/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/bow/TORSO_plate_armor_arms_shoulders.png') -0px -0px,
        url('../../assets/spritesheets/bow/TORSO_plate_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/bow/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/bow/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/bow/BODY_animation.png') -0px -0px;
}
.armored-warrior.die{
    background:
        url('../../assets/spritesheets/hurt/HEAD_plate_armor_helmet.png') -0px -0px,
        url('../../assets/spritesheets/hurt/LEGS_plate_armor_pants.png') -0px -0px,
        url('../../assets/spritesheets/hurt/TORSO_plate_armor_arms_shoulders.png') -0px -0px,
        url('../../assets/spritesheets/hurt/TORSO_plate_armor_torso.png') -0px -0px,
        url('../../assets/spritesheets/hurt/FEET_plate_armor_shoes.png') -0px -0px,
        url('../../assets/spritesheets/hurt/HANDS_plate_armor_gloves.png') -0px -0px,
        url('../../assets/spritesheets/hurt/BODY_male.png') -0px -0px;
}

.face-left.idle{
    animation-name: direction-left-idle;
    animation-timing-function: steps(1);
}
.face-up.idle{
    animation-name: direction-up-idle;
    animation-timing-function: steps(1);
}
.face-right.idle{
    animation-name: direction-right-idle;
    animation-timing-function: steps(1);
}
.face-down.idle{
    animation-name: direction-down-idle;
    animation-timing-function: steps(1);
}

.face-left.walk{
    animation-name: direction-left-walk;
    animation-timing-function: steps(9);
}
.face-up.walk{
    animation-name: direction-up-walk;
    animation-timing-function: steps(9);
}
.face-right.walk{
    animation-name: direction-right-walk;
    animation-timing-function: steps(9);
}
.face-down.walk{
    animation-name: direction-down-walk;
    animation-timing-function: steps(9);
}

.face-left.attack{
    animation-name: direction-left-attack;
    animation-timing-function: steps(6);
    animation-duration: var(--attack-speed)!important;
}
.face-up.attack{
    animation-name: direction-up-attack;
    animation-timing-function: steps(6);
    animation-duration: var(--attack-speed)!important;
}
.face-right.attack{
    animation-name: direction-right-attack;
    animation-timing-function: steps(6);
    animation-duration: var(--attack-speed)!important;
}
.face-down.attack{
    animation-name: direction-down-attack;
    animation-timing-function: steps(6);
    animation-duration: var(--attack-speed)!important;
}

.face-left.die, .face-up.die, .face-right.die, .face-down.die{
    animation-name: direction-up-die;
    animation-timing-function: steps(5);
    animation-iteration-count: 1;
}

.face-left.shoot{
    animation-name: direction-left-shoot;
    animation-timing-function: steps(13);
}
.face-up.shoot{
    animation-name: direction-up-shoot;
    animation-timing-function: steps(13);
}
.face-right.shoot{
    animation-name: direction-right-shoot;
    animation-timing-function: steps(13);
}
.face-down.shoot{
    animation-name: direction-down-shoot;
    animation-timing-function: steps(13);
}

@keyframes direction-up-idle {
    from { background-position: 0 0; }
    to { background-position: 0 0; }
}
@keyframes direction-right-idle {
    from { background-position: 0 63px; }
    to { background-position: 0 63px; }
}
@keyframes direction-down-idle {
    from { background-position: 0 127px; }
    to { background-position: 0 127px; }
}
@keyframes direction-left-idle {
    from { background-position: 0 193px; }
    to { background-position: 0 193px; }
}

@keyframes direction-up-walk {
    from { background-position: 0 0; }
    to { background-position: -576px 0; }
}
@keyframes direction-right-walk {
    from { background-position: 0 63px; }
    to { background-position: -576px 63px; }
}
@keyframes direction-down-walk {
    from { background-position: 0 127px; }
    to { background-position: -576px 127px; }
}
@keyframes direction-left-walk {
    from { background-position: 0 193px; }
    to { background-position: -576px 193px; }
}

@keyframes direction-up-attack {
    from { background-position: 0 0; }
    to { background-position: -383px 0; }
}
@keyframes direction-right-attack {
    from { background-position: 0 63px; }
    to { background-position: -383px 63px; }
}
@keyframes direction-down-attack {
    from { background-position: 0 127px; }
    to { background-position: -383px 127px; }
}
@keyframes direction-left-attack {
    from { background-position: 0 193px; }
    to { background-position: -383px 193px; }
}

@keyframes direction-up-die {
    from { background-position: 0 0; }
    to { background-position: -319px 0; }
}

@keyframes direction-up-shoot {
    from { background-position: 0 0; }
    to { background-position: -832px 0; }
}
@keyframes direction-right-shoot {
    from { background-position: 0 63px; }
    to { background-position: -832px 63px; }
}
@keyframes direction-down-shoot {
    from { background-position: 0 127px; }
    to { background-position: -832px 127px; }
}
@keyframes direction-left-shoot {
    from { background-position: 0 193px; }
    to { background-position: -832px 193px; }
}
.loop{
    animation-iteration-count: infinite!important;
}
</style>
